import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function FaqsPartner(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

//   useEffect(() => {
//     setCount(1);
//   }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="menu-link-page" >
     
      <>
  <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1\n\t{margin-top:20.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:20.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_71edkf7pljt1" />
      <span
        lang="EN-GB"
        style={{ fontSize: "24.0pt", lineHeight: "115%", color: "#11193B" }}
      >
        How long does it take to process a withdrawal?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        For security reasons, Infiniteway processes withdrawals after a manual
        review. It could take up to 48 hours.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_ih6nfu4tuq0e" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How do I edit my business information?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If the company s ownership or structure has changed, please contact our
        support team and explain the situation. They will guide you through
        email conversations.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_8uasw2bm1367" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        I want to accept cryptocurrencies on my website. Where do I start?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        First of all, you need to create an account on Infiniteway crypto
        payment gateway and get it verified. Then you will get access to several
        integration tools that will allow you to accept INW token for payment.{" "}
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_eddubrpbfuhp" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How long does the partner verification process take?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Business account verification might take up to 14 days, given that the
        documents are provided correctly. Note that our compliance department is
        reviewing documents manually, and that s why sometimes it could take
        more time than usual, especially if we re receiving a higher volume of
        requests.{" "}
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_miegumghz49e" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        What documents do I need to verify my business account?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        It all depends on the type of business you run or represent. Generally,
        companies have to provide these documents:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "15.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Business Registration (also known as Business registration
        document/certificate);
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Article of Association (AOA, also known as Constitution, Memorandum,
        Articles of Incorporation);
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "15.0pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Photo ID Document issued by the government (International Passport or ID
        card only).
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        In compliance with the local Lithuanian laws, this is what we DON T
        accept as valid ID: driver s license and internal passport.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        IMPORTANT! All required documentation must meet these conditions:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "15.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        1.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        All paperwork <i>must be </i>in English, and any translations{" "}
        <i>must be notarized</i>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        2.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Everything <i>must be sorted out </i>by the director of a company or
        authorized personnel. POA is required if acting on behalf of the
        director or company.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "15.0pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        3.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        All paperwork <i>must be official</i> meaning it has to be stamped
        and/or signed, depending on the local laws of a customer.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_d1940mp2g4a0" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        My ID documents are expiring. What I need to do?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If your ID nears to an expiration date, you will receive an email three
        months prior containing a warning to update outdated documents.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        In case the ID document with a valid expiry date won t be provided in a
        given time period, some of our services, like fiat withdrawals, will be
        suspended until the documents are renewed according to requirements
        listed in these articles:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "15.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Trader verification
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "15.0pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Business verification
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginTop: "11.0pt", background: "white" }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If you have any further questions, don t hesitate to reach out to our
        support team.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginTop: "11.0pt", background: "white" }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_76c5gi26w31" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        My local currency is not on the list of available currencies. How I can
        price my goods?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "15.0pt",
        marginRight: "0cm",
        marginBottom: "15.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Please write to our support team, and we will add your currency to the
        list.
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_b3egb81g050e" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Payments are not working properly. What might be the issue?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If you re unable to create order / unable to send a request to
        Infiniteway API, the most common issues are:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "15.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        API credentials used in the wrong environment.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Wrong API credentials.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Wrong API credentials signature, invalid order, etc.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        API requests are coming from a different IP address(es) than the IPs
        that were whitelisted when creating API credentials when creating.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        URL or another library used to communicate with the Infiniteway API is
        working incorrectly, or is outdated.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "15.0pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Your server cannot reach indcoinx.com.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If the Order status is not updated / Infiniteway does not send Payment
        Callback (Payment Notification), the most common issues are:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "15.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Your server is on a private network, for example localhost.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Your website is disabled or in maintenance mode.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "15.0pt",
        marginLeft: "51.0pt",
        textIndent: "-18.0pt",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Your server firewall, third-party security service (Cloudflare,
        Incapsula, etc.) or your application is blocking Infiniteway IP
        address(es).
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginTop: "11.0pt", background: "white" }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If the solution is not listed there, please contact our support team.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_oj6opdkc9sta" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        What currencies can I get paid in (for my sales)?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        You can only get paid in INW token under Binance Smart Chain.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_dx3slbmi8w1q" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How can I integrate Infiniteway API credentials?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        By the time you register your Infiniteway account, the support team will
        contact you for integration purposes.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Please create different accounts for each website where you are using
        Infinitway services.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_2nuim719ur3z" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How can my customers make payments with cryptocurrencies?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "0cm",
        background: "white"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        They will be directed to Infiniteway payment page, our page will guide
        your customers for making the payment.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginTop: "11.0pt", background: "white" }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If your customers don t know how to make a payment, please refer them to
        this{" "}
      </span>
      <span lang="EN-GB" style={{ color: "black" }}>
        <a href="https://docs.google.com/document/u/0/d/1SmD1rAtHQLCfVvykCEvrqYP5xncsTjIgiNrkvbiVTAc/edit">
          <span
            style={{
              fontSize: "11.5pt",
              lineHeight: "115%",
              color: "#1155CC",
              background: "white"
            }}
          >
            step-by-step guide
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        .
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_w0v70dt80vii" />
      <span
        lang="EN-GB"
        style={{ fontSize: "24.0pt", lineHeight: "115%", color: "#11193B" }}
      >
        How can I cancel a withdrawal?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Currently, our users cannot do it by themselves, but it is possible. If
        withdrawal status is still pending, please contact support with a
        subject line I want to cancel my withdrawal , and they will cancel the
        withdrawal request for you.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "13.0pt",
        marginLeft: "0cm",
        lineHeight: "112%",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_s8ffvr6ivonu" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "112%",
          color: "#11193B",
          background: "white"
        }}
      >
        Get started for accepting crypto payment
      </span>
    </h1>
    <p className="MsoNormal" style={{ background: "white" }}>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Let's get started! Follow these simple steps to create your account:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        1.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Click [Partner Register] in the login page or go to
      </span>
      <span lang="EN-GB">
        <a href="https://cryptopay.me/">
          <span
            style={{
              fontSize: "12.0pt",
              lineHeight: "152%",
              color: "#11193B",
              background: "white",
              textDecoration: "none"
            }}
          >
            {" "}
          </span>
        </a>
        <a href="https://www.indcoinx.com/partner-register">
          <span
            style={{
              fontSize: "12.0pt",
              lineHeight: "152%",
              color: "#1155CC",
              background: "white"
            }}
          >
            https://www.indcoinx.com/partner-register
          </span>
        </a>
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        2.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Enter your email address.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        3.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Click [Send OTP].
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "36.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{ fontSize: "12.0pt", lineHeight: "152%", color: "#11193B" }}
      >
        <img
          border={0}
          width={389}
          height={648}
          id="image1.png"
          src="images/img-withdraw-1.png"
        />
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        4.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        You will then receive an email with an OTP. Enter the OTP that was
        received in your email.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        5.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Enter your website name, your website URL, the password you'd like to
        use for your account, and confirm your password.
        <br />
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          fontFamily: "Roboto",
          color: "red",
          background: "white"
        }}
      >
        Note: Password should contain at least one uppercase, at least one
        lowercase, at least one number, at least one special character and
        minimum 6 and maximum 18
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        6.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Click [Submit]
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        7.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        The support team will connect you through email for integrating your
        website with Infiniteway crypto payment gateway. You will need to follow
        the instructions. Once the process is done, the support team will
        approve your registration request.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_orjrkxynl4ho" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How to withdraw your INW token?
      </span>
    </h1>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", marginTop: "12.0pt", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Visit the page{" "}
        </span>
        <span lang="EN-GB" style={{ color: "windowtext" }}>
          <a href="https://www.indcoinx.com/withdraw">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "152%",
                color: "#1155CC",
                background: "white"
              }}
            >
              https://www.indcoinx.com/withdraw
            </span>
          </a>
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Connect your wallet. The [Wallet Address] and [Registered email] will
          be automatically filled.
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Click [Verify User]. It will show your balance in your Infiniteway CPG
          account.
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Enter the amount for withdraw and the OTP that is received in your
          email. Infiniteway. We will charge service fee for applying to
          withdraw.
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Click [WithdrawToken]. And your withdraw request will be sent to the
          admin.
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", marginBottom: "12.0pt", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          You'll have to wait for up to 48 hours for the admin to verify your
          withdrawal.
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "0cm",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{ fontSize: "12.0pt", lineHeight: "152%", color: "#11193B" }}
      >
        <img
          border={0}
          width={450}
          height={451}
          id="image2.png"
          src="images/img-withdraw-2.png"
        />
      </span>
    </p>
  </div>
</>

   
      </div>
      
   
     
      <FooterCustom/>
    </div>
  );
}
