import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function PrivacyPolicy(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

//   useEffect(() => {
//     setCount(1);
//   }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
    {/* <div className="menu-link-page" >
     
    
  <h2>Privacy Policy</h2>
  <p>
    The Infiniteway entities mentioned in Section 11 below, collectively
    referred to as "we", "us", or "our"), as well as Users who browse and use
    our services ("Users") and Users who register for and use our services
    ("Customers")—collectively referred to as "you" and "your" throughout this
    policy—respect and preserve user privacy.</p>
    <br/>
    <p>
     When you visit, register for, or  use our "Services," which include the services provided on our websites,
    such as indcoinx.com (each a "Site" and collectively the "Sites"), or the
    Infiniteway Custody application programming interface ("API") and related
    services, this Privacy Policy outlines how we collect, use, and share
    personal information.
  </p>
  <p>
    {" "}
    1. The Information We Collect
    <br/>
     We collect the following personal information
    and documentation:
    <br/>
     Information You Provide Us Basic details about the you:
    Email Address 
    <br/>
    Information Automatically Collected Details about the device,
    browser, and app: Information about the device, operating system, and
    browser you’re using, additional device characteristics or identifiers (e.g.
    plugins, the network you connect to), IP address.
    <br/>
     Information we get from
    third parties and affiliates 
    <br/>
    Infiniteway Group of Companies ("Affiliates"):
    As a regular part of doing business, we may receive information about you
    from our Affiliates, including Basic Customer Information, Transaction
    Information, and Product Usage Information. For example, we may use your
    Wallet Information and Financial Information to convert cryptocurrency into
    fiat and enable you to make withdrawals into your bank account if you link
    your various Infiniteway accounts, such as your Infiniteway Wallet account
    or your Infiniteway Commerce account. Refer to Section 3 for further
    details. 
    <br/>
    Blockchain Data: Timestamps of transactions or events, transaction
    IDs, digital signatures, transaction values, and wallet addresses are among
    the public blockchain data that we may examine. 
    <br/>
    Information from our
    Marketing and Advertising Partners: Our marketing partners provide us with
    information, including your name and contact details. In certain cases, they
    also provide us with information about the marketing content you have read
    or the activities you have taken on our sites.
    <br/> Information from Analytics
    Providers: We obtain data about how you use the site, engage with others,
    the age group you belong to, and the answers you provide to surveys
    (sometimes even before you create an account). 
    <br/>
    Retail Information: If you
    trade with a third-party retailer using your Infiniteway account, the
    merchant may give us information about you, including your name and contact
    information, as well as the specifics of your transaction with that store.
    <br/>
    2. HOW YOUR INFORMATION IS USED 
    <br/>In addition to providing you with a safe,
    easy, productive, and personalised experience while using our services, we
    utilise your personal information for the following reasons: legal
    compliance, loss prevention, anti-fraud, and development. Find out more
    about the ways in which we use your personal data and the legal
    justifications behind each of these uses: 
    <br/>
    Use of data required to perform
    our agreement with you In order to complete and carry out our Terms and
    Condition or any other pertinent contract(s) with you, we need to use
    specific information. If we are unable to use your personal information for
    these reasons, we will have to close your account.
  </p>
  <p>
    {" "}
    To create and manage your Infiniteway account 
    <br/>
    in order to provide you access
    to our services and to enable you to create a profile and customer account.
    <br/>
    To offer you Consumer Services
    <br/>
     In order to offer you individualised services
    for transaction (buying or selling), saving, earning, spending, staking, and
    borrowing within your account; this includes hosting and managing your
    digital wallets, such as your Web3 Wallet, Digital Currency Wallet, and
    E-Money Wallet (when you link them to your Infiniteway account);
    additionally, Infiniteway will verify your identity. 
    <br/>
    To offer you
    Institutional Services Investors and institutions must allow investing,
    payment, asset listings, and account administration in order for us to offer
    you with Services. 
    <br/>
    To offer you Infiniteway API Services 
    <br/>
    In order to provide
    you with our Infiniteway APIs and infrastructure for Web3 services. 
    <br/>
    To
    provide customer support 
    <br/>
    To handle your support requests through the apps,
    websites, or email, as well as to answer questions about customer service
    and other matters. This includes offering premium customer service over the
    phone to customers who supply their phone numbers, as well as chat message
    support (including chatbots) and social media support. 
   <br/> 
    To maintain the
    integrity, safety, and security of our services <br/>
    In order to maintain the
    integrity of our Services, including account takeover ("ATO") prevention and
    support, we must: verify accounts and related activity; identify and address
    violations of our Customer Agreement or policies (including our Prohibited
    &amp; Conditional Use Policy); investigate suspicious activity; detect,
    prevent, and combat harmful or unlawful behaviour;
    
     and detect fraudulent
    behaviour. 
    <br/>
    Using data to meet our legal requirements 
    <br/>
    Your personal data must
    be collected, used, and retained in accordance with the laws and regulations
    that control our services. Failure to provide the legally required personal
    data will result in the cancellation of your account. 
    <br/>
    To verify your
    identity 
    <br/>
    To accurately identify or authenticate your identity and comply
    with additional specific anti-money laundering ("AML") or sanctions
    laws/regulations (such funds transfer obligations), we usually need to
    collect a range of personal data. In order to compare your "selfie" to the
    verification data you gave, we also use electronic identification in our
    verification operations. All of this information is safely stored by
    Infiniteway and its service partners; it is only disclosed in accordance
    with legal requirements. 
    <br/>
    To determine if you are legally qualified to get
    any certain restricted goods 
    <br/>
    When you utilise particular locally regulated
    items or engage in specific advanced trading activities (e.g. under the
    European Market Infrastructure Regulation), we may need to do additional
    verifications to ensure that you are acceptable. 
    <br/>
    To comply with additional
    legal and regulatory obligations 
    <br/>
    In order to comply with the law, our legal
    obligations, rules, law enforcement, governmental requests, other legal
    requirements, court orders, or disclosure to tax authorities, information
    may be accessed, read, maintained, and shared when we believe it is
    reasonable to do so. 
    <br/>
    Some legislative instances that may require us to
    collect, use, or disclose personal data are as follows: 
    <br/>
    In civil,
    commercial, criminal, or consumer protection matters: in the event that we
    receive a court order directing us to divulge information for the purposes
    of legal proceedings or regulatory investigations (such as orders or
    mandated requests under the Singapore Criminal Procedure Code 2010 or the
    Irish Competition and Consumer Protection Act 2014, or subpoenas from any
    court having jurisdiction in the US or Ireland). 
    <br/>
    Corporate and tax matters:
    <br/>
    duties under the Internal Revenue Service code, the Tax Consolidation Act of
    1997, and the Irish Companies Act of 2014. 
    <br/>
    Regulatory matters: to meet our
    regulatory requirements, including communicating with our regulators,
    including the Central Bank of Ireland, the UK Financial Conduct Authority,
    the Australian Securities and Investments Commission, the Monetary Authority
    of Singapore, the German Federal Financial Supervisory Authority ("BaFin"),
    the National Futures Association, the U.S. Securities and Exchange
    Commission, and the U.S. Commodity Futures Trading Commission, among others.
  </p>
  <br/>
  <p>
    {" "}
    Utilising Data for Our Justified Interests
    <br/>
     Whenever your rights and freedoms
    do not outweigh our legitimate interests, we rely on those of third parties
    (such as our other Customers and, in some situations, the broader public).
    You have the right to object to this processing and request that it be
    restricted in the EEA and the UK. For additional information, see to Section
    7. 
    <br/>
    To customise your usage of our services and improve your experience 
    <br/>
    We
    gather information about your online behaviour while you use the Services
    (e.g., when and how often pages on our Sites are accessed, and how our
    Services are used) in order to conduct fundamental metrics, customise your
    experience with our Services, and generally improve our Services.
    <br/>
     To offer
    you marketing materials <br/>
    
    We use your information to send you SMS, push
    notifications, in-app notifications, email, and mobile targeted marketing
    messages. 
    <br/>
    When you browse other applications and websites, you can also see
    advertisements for our services.
    <br/>
     To offer you promotions
     <br/>
      We utilise your
    information to offer promotions, such as offers for sweepstakes or other
    rewards, in exchange for your use of our services. 
    <br/>
    We maintain records and
    provide information to law enforcement, civil litigants, and other parties
    who might make requests under the law.
    <br/>
     Concerning maintaining the safety,
    security, and integrity of the Infiniteway Service, network, our customers,
    users, employees, property, and the public, we may preserve and share your
    information, unless otherwise required by law and depending on the
    situation. Alternatively, we may do so in order to defend ourselves in the
    event of litigation or other disputes, such as violations of our customer
    agreement and policies, or in response to requests or communications from
    customers or claimants. We may also review customer accounts and
    transactions for litigation disputes and record account details for
    litigation and settlement purposes.
    <br/>
     To promote safety, security and
    integrity 
    <br/>
    Outside of performing our contract with you, we may use and
    analyze your information to protect the integrity of our Services. For
    example, we may use it to: - log customer reports and patterns of suspicious
    behaviour to understand techniques being used by bad actors who may wish to
    interfere with the Services; 
    <br/>and -
    <br/>
     to identify and investigate patterns of
    suspicious behaviour or violations of our policies and Terms. 
    <br/>
    To research
    and innovate 
    <br/>
    We carry out surveys of your experience using the Services and
    the trading that you engage in to conduct and support research and
    innovation on topics related to our Services.
    <br/>
     To provide customer support
     <br/>
      To
    provide communications and customer support through our Customer Support
    team and User Research team, including communications with interfaces such
    as our chatbots. 
    <br/>
    Usage of data with your permission
    <br/>
     You may withdraw your
    consent at any time for future use of your information when we use it with
    your permission; this will not impact how we have used your data in the past
    based on your prior consent. Section 7 explains how you can modify your
    device-based or in-app settings at any time. 
    <br/>
    To enable device-based settings
    <br/>
    Gathering data that you consent to us receiving via the device-based options
    you enable (e.g., GPS position, camera, or photo access), which we utilise
    to deliver the features or services that are mentioned when you enable the
    setting.
    <br/>
     To offer you marketing materials 
     <br/>
     To send you SMS, push alerts,
    in-app, mobile, and email messages that are specifically tailored to your
    interests.
    <br/>
     When you browse other applications and websites, you can also see
    advertisements for our services. 
    <br/>
    Using data to safeguard your and others'
    essential interests 
    <br/>
    Information preservation, examination, and sharing with
    law enforcement and other parties
    <br/>
     In situations where someone's essential
    interests need to be protected, including in an emergency, we may keep,
    evaluate, and share information with law police and other parties. For
    instance, in situations where an Infiniteway Customer's life or well-being
    is in danger. 
    <br/>
    The legal bases on which we rely in your country may be
    different from those mentioned above if you live outside of the United
    Kingdom or the European Economic Area.
    <br/>
  </p>
  <br/>
  <p>
    {" "}
    3. HOW AND WHY YOUR INFORMATION IS SHARED 
    <br/>
    We collaborate with partners,
    service providers, and other external parties to assist us in providing our
    services, and as a result, we must disclose certain information to these
    external parties. How to do it is as follows: 
    <br/>
    Affiliates
    <br/>
     As a regular part
    of running our business and providing you with our Services, we may transmit
    the personal information that we handle and collect to other organisations,
    services, and employees that are connected to us. Refer to Section 11 for a
    list of the businesses and services we are associated with. 
    <br/>
    Linked Third
    Party Websites 
    <br/>
    The providers of those services or products may get
    information about you that Infiniteway, you, or others provide with them
    when you utilise third-party services (such as connecting your Infiniteway
    account with your bank account) or websites that are linked through our
    Services. Please be aware that your use of Infiniteway Affiliate Services
    and third-party services, which are not covered by this Privacy Policy, is
    subject to their respective terms and privacy policies. 
    <br/>
    Authorities,
    regulators, partners in the sector, and experts in advice
    <br/>
     We exchange the
    data that you provided in Section 1 with Advisors, Regulators, Tax
    Authorities, Law Enforcement, Government Agencies, and Industry Partners to:
    <br/>
    - Comply with our reporting and information sharing obligations with
    industry partners, including other Virtual Asset Service Providers (“VASPs”)
    and regulatory authorities;
    <br/>
     - Monitor, investigate, prevent, or address
    fraud and other illegal activity or security and technical issues;
    <br/>
     - Protect
    the rights, property, and safety of our Customers, Infiniteway and its
    Affiliates, or others, including to avert death or serious bodily harm.
    <br/>

    Transfer of Assets or Purchase of Company 
    <br/> 
    In the course of assessing and
    entering into such agreements, we may decide to purchase or sell assets
    and/or exchange and/or transfer information about our customers.
    Additionally, in the event that we—or our assets—are bought out, combined,
    reorganised, or undergo any other type of control shift, bankruptcy, or
    other event, your personal data may be among the assets given to the new
    owner.
    <br/>
     Third-Party Service Providers 
     <br/>
     We collaborate with outside service
    providers in order to deliver our services. We need third-party service
    providers we work with in this role to handle your information on our behalf
    in compliance with our guidelines and agreements, and to process it only as
    needed to fulfil our obligations under the contract. Various categories of
    outside service providers are engaged with us, such as:
    <br/>
     Suppliers of
    Third-Party Electronic ID Verification Services, Including Those Who Handle
    Biometric Data 
    <br/>
    suppliers for tax filing 
    <br/>
    Service providers of derivatives
    <br/>
    Retailers (to offer incentives/rewards) 
    <br/>suppliers of marketing and
    promotions (to advertise our Services) 
    <br/>
    providers of telecommunications
    technology (to send you messages, such as SMS messages) 
    <br/>
    CTF/AML service
    providers (for transaction monitoring reasons)
    <br/>
     Payment processors and
    suppliers of data hosting services (for off-site data storage) 
    <br/>
    suppliers of
    security services (for looking into security incidents and fraud)
    <br/>
     Analytics
    providers (to understand how you use our Services) 
    <br/>
    Payment processing firms
    (to handle transactions on our behalf) 
    <br/>
    Providers of document repository
    services
    <br/>
     Providers of customer service 
     <br/>
     Chatbots 
     <br/>
     4. HOW LONG DO WE HOLD YOUR
    PERSONAL DATA? 
    <br/>
    We keep your information for as long as it's necessary to
    deliver our services, meet legal requirements, or safeguard the interests of
    us or others. Although country-specific retention laws differ, we uphold
    internal retention standards based on the requirements for using
    information. This covers things like when the data was created or gathered,
    if it's still required for us to provide you with our services, if we have
    to keep the data on file to meet legal requirements (like AML/KYC compliance
    or other financial regulatory obligations), or if there are information
    preservation requirements. In order to safeguard the integrity, safety, and
    security of our services, users, and customers, we also retain some
    information. Our third-party electronic identity verification providers
    gather and hold onto biometric data for the length of time necessary to
    comply with financial regulations or as mandated by other legal
    requirements. When you close your account or request that your information
    be deleted, we take these factors into account and remove any information
    that is no longer required for the aforementioned purposes. 5. CHILDREN'S
    PERSONAL INFORMATION We do not intentionally request or collect any
    information on individuals under the age of 18, and the Sites and Services
    are not intended for use by those who are younger than that age. Please
    refrain from providing any personal information through the Sites or
    Services if you are under the age of 18. Infiniteway shall compel the
    relevant Customer or User to cancel his or her account and will take
    immediate action to remove the individual's information if it is suspected
    that the User or Customer providing the personal information is under the
    age of 18. 6. INTERNATIONAL TRANSFERS Your personal information may be
    transferred, stored, and processed anywhere in the world—including Ireland,
    Germany, Singapore, the UK, the US, and the Philippines—in order to support
    our global operations through Infiniteway, its Affiliates, third-party
    partners, and service providers. We rely on a number of legal processes to
    enable these transfers of your personal information (collectively, "European
    Personal Data") if you live in the EEA, Switzerland, or the UK. To enable
    the international and subsequent transfer of European Personal Data to third
    countries, including from our EU operating entities to Infiniteway, Inc. in
    the United States, we principally rely on the Standard Contractual Clauses
    of the European Commission. Please send an email to info@infiniteway.com to
    obtain a copy of the Standard Contractual Clauses. Furthermore, we might
    depend on specific exemptions allowed by data protection law for our
    cross-border transfers. When available, we also depend on the European
    Commission's adequacy rulings and any exemptions allowed under data
    protection legislation. For instance, in order to develop, market, and
    improve our services, Infiniteway must share information with data centres
    located outside of the EEA and with our Affiliates due to its global
    operations and service delivery. Furthermore, in an emergency, we can rely
    on specific exemptions for exchanging personal data with law enforcement
    outside of the EEA. EU-US, UK-US, and Swiss-US Data Privacy Framework
    Infiniteway complies with the EU-U.S. Data Privacy Framework (EU-U.S. DPF),
    the UK Extension to the EU-U.S. DPF and the Swiss-U.S. Data Privacy
    Framework (Swiss-U.S. DPF) (together referred to as the “Data Privacy
    Frameworks”) and the DPF Principles as set forth by the U.S. Department of
    Commerce. Infiniteway has certified to the U.S. Department of Commerce that
    it adheres to: the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF
    Principles) with regard to the processing of personal data received from the
    European Union in reliance on the EU-U.S. DPF; the UK-U.S. Data Privacy
    Framework Principles (EU-UK DPF Principles) with regard to the processing of
    personal data received from the United Kingdom (and Gibraltar) in reliance
    on the UK Extension to the EU-U.S. DPF; and the Swiss-U.S. Data Privacy
    Framework Principles (Swiss-U.S. DPF Principles) with regard to the
    processing of personal data received from Switzerland in reliance on the
    Swiss-U.S. DPF. As stated in Section 4 of this policy, Infiniteway is in
    charge of processing personal data it receives under the DPF in the event of
    an onward transfer, which it then transfers to a third party functioning as
    a partner, service provider, or other third party to assist us in providing
    our services. Unless Infiniteway can demonstrate that we are not accountable
    for the incident causing the harm, Infiniteway is nevertheless liable under
    the DPF if any such third party handles personal data in a way that is
    against the DPF. Visit https://www.dataprivacyframework.gov to view our
    certification and to learn more about the Data Privacy Framework (DPF)
    programme. Regarding the acquisition and use of your personal data,
    Infiniteway pledges to address any concerns pertaining to the DPF Principles
    in accordance with the Data Privacy Frameworks. dpo@Infiniteway.com is the
    initial point of contact for EU, UK, and Swiss individuals with questions or
    concerns about how we handle personal data that we obtain in accordance with
    the applicable Data Privacy Frameworks. As a member of and dependent on the
    ICDR-AAA services for the Data Privacy Framework Programme, see here
    https://go.adr.org/dpf_irm.html, Infiniteway is able to resolve unresolved
    complaints regarding our handling of personal information in reliance on the
    Data Privacy Frameworks. In certain cases, this may entail binding
    arbitration. Regarding Infiniteway's adherence to the EU-U.S. Data Privacy
    Framework (EU-U.S. DPF), the UK Extension to the EU-U.S. DPF, and the
    Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF), the Federal Trade
    Commission has authority. 7. PRIVACY CHOICES AND RIGHTS You might be able to
    exercise some privacy rights about your personal information, depending on
    where you live. Requests for your personal information can be made in
    relation to any of the privacy choices and rights mentioned below by signing
    into your account and visiting our Help Center, or by sending an email to
    support@infiniteway.com. Infiniteway has complete discretion over whether to
    grant you any of the following rights in the event that your operating
    organisation or country does not provide you any of these rights by law.
    Right to access and portability: Through our Help Centre, you can ask us to
    send you a copy of the personal data we own about you. Right to
    deletion/erasure: If permitted by current legislation, you may request that
    your personal information be deleted. As mentioned in Section 4, we will
    either keep or remove information related to your Infiniteway Account if you
    close it. Right to withdraw your consent: If your consent is required for
    the processing of your personal data, you have the right to revoke it at any
    time. The legality of Infiniteway's processing prior to your consent
    withdrawal will remain unaffected by this action. Right to object to or
    restrict processing: If we use or transfer your personal information for
    direct marketing, the public interest, or our legitimate interests, you may
    be able to restrict its use or object to it. If applicable law permits or
    mandates it, we might keep processing your personal data. You can request to
    stop receiving marketing communications from Infiniteway by contacting our
    Help Centre or by going via your account settings. Right to
    non-discrimination: We promise not to treat you unfairly if you exercise any
    of the legal rights that are granted to you. We may take procedures to
    confirm your identity before granting your request in order to safeguard
    your security and privacy. If we are unable to confirm your identity, we may
    reject your request. It is also possible for you to nominate an authorised
    agent to submit these requests on your behalf in Brazil and under certain US
    data privacy laws. These rights are not unassailable and may be withheld in
    the following situations: (a) when facilitating portability or allowing
    access will negatively impact the rights and freedoms of others; (b) in
    order to safeguard our property and rights; (c) in cases where the request
    is frivolous or vexatious; or (d) as otherwise allowed by law. 8. NOTICE OF
    PRIVACY FOR RESIDENTS OF THE UNITED STATES If you live in the United States,
    you may read our United States Privacy Notice to find out more about your
    rights to privacy and how we use your information. Any word used in the US
    Privacy Notice that is defined under the California Consumer Privacy Act (as
    amended) ("CCPA") has the same meaning. 9. HOW TO CONTACT US WITH QUESTIONS
    Please contact us through our Help Centre at support@infiniteway.com, by
    mail at the address of your Infiniteway service provider, or if you have any
    questions or issues about our Privacy Policy. 10. MODIFICATIONS TO THIS
    PRIVACY POLICY We may occasionally need to make changes to this Privacy
    Policy as we're always looking for ways to improve our Services. Any
    modifications we make to our privacy statement are published on this page,
    and if necessary, we will give you adequate notice of any significant
    changes before they go into effect or as required by law. The most recent
    modification to the Privacy Policy can be seen at the top of this page. In
    the context of particular Services, we might provide extra "just-in-time"
    disclosures or information about how we gather or use your information.
    These in-product statements could add to or clarify our privacy policies or
    give you more options for how we use your information. 11. OUR RELATIONSHIP
    WITH YOU Blockbridge Limited acts as a joint controller of your personal
    information if you live in the European Economic Area. As the primary joint
    controller for your personal information, Blockbridge Limited is in charge
    of giving you information and handling any requests you might have in
    accordance with the GDPR.{" "}
  </p>


  
     </div> */}
     <div className="menu-link-page">
     <>
  <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1\n\t{margin-top:20.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:20.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh2\n\t{margin-top:18.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:16.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh3\n\t{margin-top:16.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:14.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#434343;\n\tfont-weight:normal;}\np.MsoTitle, li.MsoTitle, div.MsoTitle\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:3.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:26.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <p className="MsoTitle">
      <a name="_hy5knr6qiru6" />
      <span lang="EN-GB">Privacy Policy</span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span lang="EN-GB" style={{ color: "black" }}>
          The Infiniteway entities mentioned in{" "}
          <a href="#_fgxea49kw8ng">
            <span style={{ color: "#1155CC" }}>Section 11</span>
          </a>{" "}
          below, collectively referred to as "we", "us", or "our"), as well as
          Users who browse and use our services ("Users") and Users who register
          for and use our services ("Customers") collectively referred to as
          "you" and "your" throughout this policy respect and preserve user
          privacy.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span lang="EN-GB" style={{ color: "black" }}>
          When you visit, register for, or use our "Services," which include the
          services provided on our websites, such as
          <a href="http://indcoinx.com">
            <span style={{ color: "#1155CC" }}> indcoinx.com</span>
          </a>
          (each a "Site" and collectively the "Sites"), or the Infiniteway
          Custody application programming interface ("API") and related
          services, this Privacy Policy outlines how we collect, use, and share
          personal information.{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span lang="EN-GB">&nbsp;</span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_80n1zl1ny50p" />
        <span lang="EN-GB" style={{ color: "black" }}>
          1. The Information We Collect
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span lang="EN-GB" style={{ fontFamily: "Roboto", color: "black" }}>
          We collect the following personal information and documentation:
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_ucl42aajqls8" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Information You Provide Us
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Basic details about the you: Email Address{" "}
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_nimt4yl5dirm" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Information Automatically Collected
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Details about the device, browser, and app: Information about the
          device, operating system, and browser you re using, additional device
          characteristics or identifiers (e.g. plugins, the network you connect
          to), IP address.
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <a name="_4tmipanfz3g8" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Information we get from third parties and affiliates
        </span>
      </h2>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Infiniteway Group of Companies ("Affiliates"): As a regular part of
          doing business, we may receive information about you from our
          Affiliates, including Basic Customer Information, Transaction
          Information, and Product Usage Information. For example, we may use
          your Wallet Information and Financial Information to convert
          cryptocurrency into fiat and enable you to make withdrawals into your
          bank account if you link your various Infiniteway accounts, such as
          your Infiniteway Wallet account or your Infiniteway Commerce account.
          Refer to{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="#_12431sqeoylc">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Section 3
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
          for further details.{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Blockchain Data: Timestamps of transactions or events, transaction
          IDs, digital signatures, transaction values, and wallet addresses are
          among the public blockchain data that we may examine.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Information from our Marketing and Advertising Partners: Our marketing
          partners provide us with information, including your name and contact
          details. In certain cases, they also provide us with information about
          the marketing content you have read or the activities you have taken
          on our sites.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Information from Analytics Providers: We obtain data about how you use
          the site, engage with others, the age group you belong to, and the
          answers you provide to surveys (sometimes even before you create an
          account).
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Retail Information: If you trade with a third-party retailer using
          your Infiniteway account, the merchant may give us information about
          you, including your name and contact information, as well as the
          specifics of your transaction with that store.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white"
      }}
    >
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_yxh0yrnpskg0" />
        <span lang="EN-GB" style={{ color: "black" }}>
          2. HOW YOUR INFORMATION IS USED
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In addition to providing you with a safe, easy, productive, and
          personalised experience while using our services, we utilise your
          personal information for the following reasons: legal compliance, loss
          prevention, anti-fraud, and development. Find out more about the ways
          in which we use your personal data and the legal justifications behind
          each of these uses:
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_1zeghxroy09z" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Use of data required to perform our agreement with you
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In order to complete and carry out our{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="https://docs.google.com/document/d/10AY21CCi7JS71QzlYrxoxYhmDxPC5TQgEgI1UWgRf0Q/">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Terms and Condition
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
          or any other pertinent contract(s) with you, we need to use specific
          information. If we are unable to use your personal information for
          these reasons, we will have to close your account.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_h882cokq8peq" />
        <span lang="EN-GB">To create and manage your Infiniteway account</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          in order to provide you access to our services and to enable you to
          create a profile and customer account.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_8kiw9uqygugv" />
        <span lang="EN-GB">To offer you Consumer Services</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In order to offer you individualised services for transaction (buying
          or selling), saving, earning, spending, staking, and borrowing within
          your account; this includes hosting and managing your digital wallets,
          such as your Web3 Wallet, Digital Currency Wallet, and E-Money Wallet
          (when you link them to your Infiniteway account); additionally,
          Infiniteway will verify your identity.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_swek9lwho33g" />
        <span lang="EN-GB">To offer you Institutional Services</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Investors and institutions must allow investing, payment, asset
          listings, and account administration in order for us to offer you with
          Services.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_xt2ad3a2rth6" />
        <span lang="EN-GB">To offer you Infiniteway API Services</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In order to provide you with our Infiniteway APIs and infrastructure
          for Web3 services.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_pj4fg5d7mn0d" />
        <span lang="EN-GB">To provide customer support</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          To handle your support requests through the apps, websites, or email,
          as well as to answer questions about customer service and other
          matters. This includes offering premium customer service over the
          phone to customers who supply their phone numbers, as well as chat
          message support (including chatbots) and social media support.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_p9mtevwn7x3l" />
        <span lang="EN-GB">
          To maintain the integrity, safety, and security of our services
        </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In order to maintain the integrity of our Services, including account
          takeover ("ATO") prevention and support, we must: verify accounts and
          related activity; identify and address violations of our Customer
          Agreement or policies (including our Prohibited &amp; Conditional Use
          Policy); investigate suspicious activity; detect, prevent, and combat
          harmful or unlawful behaviour; and detect fraudulent behaviour.
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_ajscj0ue2e6" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Using data to meet our legal requirements
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Your personal data must be collected, used, and retained in accordance
          with the laws and regulations that control our services. Failure to
          provide the legally required personal data will result in the
          cancellation of your account.{" "}
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_s85dcfpbpsgk" />
        <span lang="EN-GB">To verify your identity</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          To accurately identify or authenticate your identity and comply with
          additional specific anti-money laundering ("AML") or sanctions
          laws/regulations (such funds transfer obligations), we usually need to
          collect a range of personal data. In order to compare your "selfie" to
          the verification data you gave, we also use electronic identification
          in our verification operations. All of this information is safely
          stored by Infiniteway and its service partners; it is only disclosed
          in accordance with legal requirements.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_dw7v3jrdtuxq" />
        <span lang="EN-GB">
          To determine if you are legally qualified to get any certain
          restricted goods
        </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          When you utilise particular locally regulated items or engage in
          specific advanced trading activities (e.g. under the European Market
          Infrastructure Regulation), we may need to do additional verifications
          to ensure that you are acceptable.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_a0n3kvl6ez8g" />
        <span lang="EN-GB">
          To comply with additional legal and regulatory obligations
        </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In order to comply with the law, our legal obligations, rules, law
          enforcement, governmental requests, other legal requirements, court
          orders, or disclosure to tax authorities, information may be accessed,
          read, maintained, and shared when we believe it is reasonable to do
          so.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Some legislative instances that may require us to collect, use, or
          disclose personal data are as follows:{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            In civil, commercial, criminal, or consumer protection matters:{" "}
          </span>
        </b>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          in the event that we receive a court order directing us to divulge
          information for the purposes of legal proceedings or regulatory
          investigations (such as orders or mandated requests under the
          Singapore Criminal Procedure Code 2010 or the Irish Competition and
          Consumer Protection Act 2014, or subpoenas from any court having
          jurisdiction in the US or Ireland).
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            Corporate and tax matters:
          </span>
        </b>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
          duties under the Internal Revenue Service code, the Tax Consolidation
          Act of 1997, and the Irish Companies Act of 2014.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            Regulatory matters:{" "}
          </span>
        </b>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          to meet our regulatory requirements, including communicating with our
          regulators, including the Central Bank of Ireland, the UK Financial
          Conduct Authority, the Australian Securities and Investments
          Commission, the Monetary Authority of Singapore, the German Federal
          Financial Supervisory Authority ("BaFin"), the National Futures
          Association, the U.S. Securities and Exchange Commission, and the U.S.
          Commodity Futures Trading Commission, among others.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_galvv76niylm" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Utilising Data for Our Justified Interests
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Whenever your rights and freedoms do not outweigh our legitimate
          interests, we rely on those of third parties (such as our other
          Customers and, in some situations, the broader public). You have the
          right to object to this processing and request that it be restricted
          in the EEA and the UK. For additional information, see to{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="#_gl3zrg75d4xm">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Section 7
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          .
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_tyu60xvw11lo" />
        <span lang="EN-GB">
          To customise your usage of our services and improve your experience
        </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We gather information about your online behaviour while you use the
          Services (e.g., when and how often pages on our Sites are accessed,
          and how our Services are used) in order to conduct fundamental
          metrics, customise your experience with our Services, and generally
          improve our Services.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_1x69zafxa4o3" />
        <span lang="EN-GB">To offer you marketing materials</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We use your information to send you SMS, push notifications, in-app
          notifications, email, and mobile targeted marketing messages.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          When you browse other applications and websites, you can also see
          advertisements for our services.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_8cr3p1mz713o" />
        <span lang="EN-GB">To offer you promotions</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We utilise your information to offer promotions, such as offers for
          sweepstakes or other rewards, in exchange for your use of our
          services.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_ilz7s9mzg9j3" />
        <span lang="EN-GB">
          We maintain records and provide information to law enforcement, civil
          litigants, and other parties who might make requests under the law.{" "}
        </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Concerning maintaining the safety, security, and integrity of the
          Infiniteway Service, network, our customers, users, employees,
          property, and the public, we may preserve and share your information,
          unless otherwise required by law and depending on the situation.
          Alternatively, we may do so in order to defend ourselves in the event
          of litigation or other disputes, such as violations of our customer
          agreement and policies, or in response to requests or communications
          from customers or claimants. We may also review customer accounts and
          transactions for litigation disputes and record account details for
          litigation and settlement purposes.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_irkl1uu8ojlw" />
        <span lang="EN-GB">To promote safety, security and integrity </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Outside of performing our contract with you, we may use and analyze
          your information to protect the integrity of our Services. For
          example, we may use it to:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          - log customer reports and patterns of suspicious behaviour to
          understand techniques being used by bad actors who may wish to
          interfere with the Services; and
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          - to identify and investigate patterns of suspicious behaviour or
          violations of our policies and Terms.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_996gbuz5gfyq" />
        <span lang="EN-GB">To research and innovate</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We carry out surveys of your experience using the Services and the
          trading that you engage in to conduct and support research and
          innovation on topics related to our Services.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_oq7tf2rholfr" />
        <span lang="EN-GB">To provide customer support</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          To provide communications and customer support through our Customer
          Support team and User Research team, including communications with
          interfaces such as our chatbots.
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_ggenqxf9tsrx" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Usage of data with your permission
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          You may withdraw your consent at any time for future use of your
          information when we use it with your permission; this will not impact
          how we have used your data in the past based on your prior consent.{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="#_gl3zrg75d4xm">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Section 7
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
          explains how you can modify your device-based or in-app settings at
          any time.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_7ozgfcqbj4d5" />
        <span lang="EN-GB">To enable device-based settings</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Gathering data that you consent to us receiving via the device-based
          options you enable (e.g., GPS position, camera, or photo access),
          which we utilise to deliver the features or services that are
          mentioned when you enable the setting.
        </span>
      </p>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_xmjeq6268nmq" />
        <span lang="EN-GB">To offer you marketing materials</span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          To send you SMS, push alerts, in-app, mobile, and email messages that
          are specifically tailored to your interests.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          When you browse other applications and websites, you can also see
          advertisements for our services.
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_4irje34m5x5l" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Using data to safeguard your and others' essential interests
        </span>
      </h2>
      <h3
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_guzxam2pyv4s" />
        <span lang="EN-GB">
          Information preservation, examination, and sharing with law
          enforcement and other parties
        </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In situations where someone's essential interests need to be
          protected, including in an emergency, we may keep, evaluate, and share
          information with law police and other parties. For instance, in
          situations where an Infiniteway Customer's life or well-being is in
          danger.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          The legal bases on which we rely in your country may be different from
          those mentioned above if you live outside of the United Kingdom or the
          European Economic Area.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_12431sqeoylc" />
        <span lang="EN-GB" style={{ color: "black" }}>
          3. HOW AND WHY YOUR INFORMATION IS SHARED
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We collaborate with partners, service providers, and other external
          parties to assist us in providing our services, and as a result, we
          must disclose certain information to these external parties. How to do
          it is as follows:
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_u8ep5eibr4df" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Affiliates
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          As a regular part of running our business and providing you with our
          Services, we may transmit the personal information that we handle and
          collect to other organisations, services, and employees that are
          connected to us. Refer to{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="#_fgxea49kw8ng">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Section 11{" "}
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          for a list of the businesses and services we are associated with.{" "}
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_96ppntyi8uvn" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Linked Third Party Websites
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          The providers of those services or products may get information about
          you that Infiniteway, you, or others provide with them when you
          utilise third-party services (such as connecting your Infiniteway
          account with your bank account) or websites that are linked through
          our Services. Please be aware that your use of Infiniteway Affiliate
          Services and third-party services, which are not covered by this
          Privacy Policy, is subject to their respective terms and privacy
          policies.
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_jvqu54h5bh9x" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Authorities, regulators, partners in the sector, and experts in advice
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We exchange the data that you provided in{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="#_80n1zl1ny50p">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Section 1
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
          with Advisors, Regulators, Tax Authorities, Law Enforcement,
          Government Agencies, and Industry Partners to:{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          - Comply with our reporting and information sharing obligations with
          industry partners, including other Virtual Asset Service Providers (
          VASPs ) and regulatory authorities;{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          - Monitor, investigate, prevent, or address fraud and other illegal
          activity or security and technical issues;{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          - Protect the rights, property, and safety of our Customers,
          Infiniteway and its Affiliates, or others, including to avert death or
          serious bodily harm.
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_97t8u1eym7ti" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Transfer of Assets or Purchase of Company
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In the course of assessing and entering into such agreements, we may
          decide to purchase or sell assets and/or exchange and/or transfer
          information about our customers. Additionally, in the event that we or
          our assets are bought out, combined, reorganised, or undergo any other
          type of control shift, bankruptcy, or other event, your personal data
          may be among the assets given to the new owner.{" "}
        </span>
      </p>
      <h2
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_p002i3nifrr2" />
        <span lang="EN-GB" style={{ color: "black" }}>
          Third-Party Service Providers
        </span>
      </h2>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We collaborate with outside service providers in order to deliver our
          services. We need third-party service providers we work with in this
          role to handle your information on our behalf in compliance with our
          guidelines and agreements, and to process it only as needed to fulfil
          our obligations under the contract. Various categories of outside
          service providers are engaged with us, such as:
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Suppliers of Third-Party Electronic ID Verification Services,
          Including Those Who Handle Biometric Data
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          suppliers for tax filing
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Service providers of derivatives
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Retailers (to offer incentives/rewards)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          suppliers of marketing and promotions (to advertise our Services)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          providers of telecommunications technology (to send you messages, such
          as SMS messages)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          CTF/AML service providers (for transaction monitoring reasons)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Payment processors and suppliers of data hosting services (for
          off-site data storage)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          suppliers of security services (for looking into security incidents
          and fraud)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Analytics providers (to understand how you use our Services)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Payment processing firms (to handle transactions on our behalf)
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Providers of document repository services
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Providers of customer service
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Chatbots{" "}
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white"
      }}
    >
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_gn29jcw2s9p1" />
        <span lang="EN-GB" style={{ color: "black" }}>
          4. HOW LONG DO WE HOLD YOUR PERSONAL DATA?
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We keep your information for as long as it's necessary to deliver our
          services, meet legal requirements, or safeguard the interests of us or
          others. Although country-specific retention laws differ, we uphold
          internal retention standards based on the requirements for using
          information. This covers things like when the data was created or
          gathered, if it's still required for us to provide you with our
          services, if we have to keep the data on file to meet legal
          requirements (like AML/KYC compliance or other financial regulatory
          obligations), or if there are information preservation requirements.
          In order to safeguard the integrity, safety, and security of our
          services, users, and customers, we also retain some information. Our
          third-party electronic identity verification providers gather and hold
          onto biometric data for the length of time necessary to comply with
          financial regulations or as mandated by other legal requirements. When
          you close your account or request that your information be deleted, we
          take these factors into account and remove any information that is no
          longer required for the aforementioned purposes.
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_4hwiva4eb8hl" />
        <span lang="EN-GB" style={{ color: "black" }}>
          5. CHILDREN'S PERSONAL INFORMATION
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We do not intentionally request or collect any information on
          individuals under the age of 18, and the Sites and Services are not
          intended for use by those who are younger than that age. Please
          refrain from providing any personal information through the Sites or
          Services if you are under the age of 18. Infiniteway shall compel the
          relevant Customer or User to cancel his or her account and will take
          immediate action to remove the individual's information if it is
          suspected that the User or Customer providing the personal information
          is under the age of 18.
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_zib27qgi9olm" />
        <span lang="EN-GB" style={{ color: "black" }}>
          6. INTERNATIONAL TRANSFERS
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Your personal information may be transferred, stored, and processed
          anywhere in the world including Ireland, Germany, Singapore, the UK,
          the US, and the Philippines in order to support our global operations
          through Infiniteway, its Affiliates, third-party partners, and service
          providers.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We rely on a number of legal processes to enable these transfers of
          your personal information (collectively, "European Personal Data") if
          you live in the EEA, Switzerland, or the UK.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          To enable the international and subsequent transfer of European
          Personal Data to third countries, including from our EU operating
          entities to Infiniteway, Inc. in the United States, we principally
          rely on the Standard Contractual Clauses of the European Commission.
          Please send an email to info@infiniteway.com to obtain a copy of the
          Standard Contractual Clauses.{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Furthermore, we might depend on specific exemptions allowed by data
          protection law for our cross-border transfers. When available, we also
          depend on the European Commission's adequacy rulings and any
          exemptions allowed under data protection legislation. For instance, in
          order to develop, market, and improve our services, Infiniteway must
          share information with data centres located outside of the EEA and
          with our Affiliates due to its global operations and service delivery.
          Furthermore, in an emergency, we can rely on specific exemptions for
          exchanging personal data with law enforcement outside of the EEA.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <u>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            EU-US, UK-US, and Swiss-US Data Privacy Framework
          </span>
        </u>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Infiniteway complies with the EU-U.S. Data Privacy Framework (EU-U.S.
          DPF), the UK Extension to the EU-U.S. DPF and the Swiss-U.S. Data
          Privacy Framework (Swiss-U.S. DPF) (together referred to as the Data
          Privacy Frameworks ) and the DPF Principles as set forth by the U.S.
          Department of Commerce. Infiniteway has certified to the U.S.
          Department of Commerce that it adheres to:
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF Principles)
          with regard to the processing of personal data received from the
          European Union in reliance on the EU-U.S. DPF;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          the UK-U.S. Data Privacy Framework Principles (EU-UK DPF Principles)
          with regard to the processing of personal data received from the
          United Kingdom (and Gibraltar) in reliance on the UK Extension to the
          EU-U.S. DPF; and
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          the Swiss-U.S. Data Privacy Framework Principles (Swiss-U.S. DPF
          Principles) with regard to the processing of personal data received
          from Switzerland in reliance on the Swiss-U.S. DPF.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          As stated in{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="#_gn29jcw2s9p1">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Section 4
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
          of this policy, Infiniteway is in charge of processing personal data
          it receives under the DPF in the event of an onward transfer, which it
          then transfers to a third party functioning as a partner, service
          provider, or other third party to assist us in providing our services.
          Unless Infiniteway can demonstrate that we are not accountable for the
          incident causing the harm, Infiniteway is nevertheless liable under
          the DPF if any such third party handles personal data in a way that is
          against the DPF. Visit https://www.dataprivacyframework.gov to view
          our certification and to learn more about the Data Privacy Framework
          (DPF) programme.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Regarding the acquisition and use of your personal data, Infiniteway
          pledges to address any concerns pertaining to the DPF Principles in
          accordance with the Data Privacy Frameworks. dpo@Infiniteway.com is
          the initial point of contact for EU, UK, and Swiss individuals with
          questions or concerns about how we handle personal data that we obtain
          in accordance with the applicable Data Privacy Frameworks.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          As a member of and dependent on the ICDR-AAA services for the Data
          Privacy Framework Programme, see here https://go.adr.org/dpf_irm.html,
          Infiniteway is able to resolve unresolved complaints regarding our
          handling of personal information in reliance on the Data Privacy
          Frameworks. In certain cases, this may entail binding arbitration.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Regarding Infiniteway's adherence to the EU-U.S. Data Privacy
          Framework (EU-U.S. DPF), the UK Extension to the EU-U.S. DPF, and the
          Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF), the Federal Trade
          Commission has authority.
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_gl3zrg75d4xm" />
        <span lang="EN-GB" style={{ color: "black" }}>
          7. PRIVACY CHOICES AND RIGHTS
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          You might be able to exercise some privacy rights about your personal
          information, depending on where you live. Requests for your personal
          information can be made in relation to any of the privacy choices and
          rights mentioned below by signing into your account and visiting our
          Help Center, or by sending an email to support@infiniteway.com.
          Infiniteway has complete discretion over whether to grant you any of
          the following rights in the event that your operating organisation or
          country does not provide you any of these rights by law.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            Right to access and portability
          </span>
        </b>
        <i>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            :{" "}
          </span>
        </i>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "54.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Through our Help Centre, you can ask us to send you a copy of the
          personal data we own about you.{" "}
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            Right to deletion/erasure
          </span>
        </b>
        <i>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            :
          </span>
        </i>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "54.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          If permitted by current legislation, you may request that your
          personal information be deleted. As mentioned in{" "}
        </span>
        <span lang="EN-GB" style={{ color: "black" }}>
          <a href="#_gn29jcw2s9p1">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              Section 4
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          , we will either keep or remove information related to your
          Infiniteway Account if you close it.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            Right to withdraw your consent
          </span>
        </b>
        <i>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            :
          </span>
        </i>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "54.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          If your consent is required for the processing of your personal data,
          you have the right to revoke it at any time. The legality of
          Infiniteway's processing prior to your consent withdrawal will remain
          unaffected by this action.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            Right to object to or restrict processing
          </span>
        </b>
        <i>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            :
          </span>
        </i>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          {" "}
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "54.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          If we use or transfer your personal information for direct marketing,
          the public interest, or our legitimate interests, you may be able to
          restrict its use or object to it. If applicable law permits or
          mandates it, we might keep processing your personal data. You can
          request to stop receiving marketing communications from Infiniteway by
          contacting our Help Centre or by going via your account settings.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          background: "white",
          border: "none",
          padding: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <b>
          <span
            lang="EN-GB"
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#5B616E",
              background: "white"
            }}
          >
            Right to non-discrimination
          </span>
        </b>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          : We promise not to treat you unfairly if you exercise any of the
          legal rights that are granted to you.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none black 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        background: "white"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We may take procedures to confirm your identity before granting your
          request in order to safeguard your security and privacy. If we are
          unable to confirm your identity, we may reject your request.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          It is also possible for you to nominate an authorised agent to submit
          these requests on your behalf in Brazil and under certain US data
          privacy laws.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          These rights are not unassailable and may be withheld in the following
          situations: (a) when facilitating portability or allowing access will
          negatively impact the rights and freedoms of others; (b) in order to
          safeguard our property and rights; (c) in cases where the request is
          frivolous or vexatious; or (d) as otherwise allowed by law.{" "}
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_xl6k4iwxbll" />
        <span lang="EN-GB" style={{ color: "black" }}>
          8. NOTICE OF PRIVACY FOR RESIDENTS OF THE UNITED STATES
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          If you live in the United States, you may read our United States
          Privacy Notice to find out more about your rights to privacy and how
          we use your information. Any word used in the US Privacy Notice that
          is defined under the California Consumer Privacy Act (as amended)
          ("CCPA") has the same meaning.
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_l1eipb99s3kq" />
        <span lang="EN-GB" style={{ color: "black" }}>
          9. HOW TO CONTACT US WITH QUESTIONS
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Please contact us through our Help Centre at support@infiniteway.com,
          by mail at the address of your Infiniteway service provider, or if you
          have any questions or issues about our Privacy Policy.{" "}
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_b1098cve1xn0" />
        <span lang="EN-GB" style={{ color: "black" }}>
          10. MODIFICATIONS TO THIS PRIVACY POLICY
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          We may occasionally need to make changes to this Privacy Policy as
          we're always looking for ways to improve our Services. Any
          modifications we make to our privacy statement are published on this
          page, and if necessary, we will give you adequate notice of any
          significant changes before they go into effect or as required by law.
          The most recent modification to the Privacy Policy can be seen at the
          top of this page.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          In the context of particular Services, we might provide extra
          "just-in-time" disclosures or information about how we gather or use
          your information. These in-product statements could add to or clarify
          our privacy policies or give you more options for how we use your
          information.
        </span>
      </p>
      <h1
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <a name="_fgxea49kw8ng" />
        <span lang="EN-GB" style={{ color: "black" }}>
          11. OUR RELATIONSHIP WITH YOU
        </span>
      </h1>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          Blockbridge Limited acts as a joint controller of your personal
          information if you live in the European Economic Area. As the primary
          joint controller for your personal information, Blockbridge Limited is
          in charge of giving you information and handling any requests you
          might have in accordance with the GDPR.{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none black"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          background: "white",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            color: "#5B616E",
            background: "white"
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
  </div>
</>

        </div>
      <FooterCustom/>
    </div>
  );
}
