import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function PartnerHelpCenter(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

//   useEffect(() => {
//     setCount(1);
//   }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="menu-link-page" >
     
     <p> Partner help center</p>
  
     </div>
      <FooterCustom/>
    </div>
  );
}
