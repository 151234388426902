import React,{ Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import StripePaymentElement from './pages/StripePayment';
import BuyTokensElement from './pages/BuyTokens'
import Buy from './pages/buy'
import Login from './pages/login'
import ForgotPassword from './pages/ForgotPassword'
import AboutUs from './pages/aboutUs';
import PrivacyPolicy from './pages/privacyPolicy';
import RiskAssessmentPolicy from './pages/riskAssesmentPolicy';
import AmlCftPolicy from './pages/amlCftPolicy';
import TermsAndService from './pages/termsAndService';
import UserHelpCenter from './pages/userHelpCenter';
import PartnerHelpCenter from './pages/partnerHelpCenter';
import FaqsPartner from './pages/faqsPartner';
import FaqsUser from './pages/faqsUser';
import ResetPassword from './pages/ResetPassword'
import Home from './pages/home'
import Register from './pages/Register';
import Withdraw from './pages/withdraw'
import Deposit from './pages/deposit'
import Reports from './pages/reports'
import Report from './pages/Report';
import Contact from './pages/contact'
import Receipt from './pages/receipt';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
import  Counter from './pages/count';
import ConditionRoute from './condtionRouter';
import ReduxHelper from './pages/ReduxHelper';
import PartnerRegister from './pages/partner-register';
import PartnerDashboard from './pages/partner-dashboard';
import PartnerSiteReport from './pages/partner-site-report';
import TransactionReport from './pages/transaction-report';

import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { bsc, bscTestnet, polygon, polygonMumbai } from 'viem/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet],
  [publicProvider()],
)

console.log(chains,'chains399')
// Set up wagmi config
const config = createConfig({

  autoConnect: true,
  connectors: [


    new MetaMaskConnector({ chains ,
      shimDisconnect: true,
    }),

    new WalletConnectConnector({
      chains,
      options: {
        projectId: '681700e231a5aef269b7fe4adb34981a',
        version: '2',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})
const App = (props) => {

    return (
      <div className="App">
        <Suspense fallback={null}>
        <ToastContainer />
        <WagmiConfig config={config}>
          <Routes>
              {/* <Route path="/buy" element={<BuyTokensElement />}></Route> */}
              <Route path="/transaction-report/:userId" element={<TransactionReport />}></Route>
              <Route path="/partner-site-report" element={<PartnerSiteReport />}></Route>
              <Route path="/partner-dashboard" element={<PartnerDashboard />}></Route>
              <Route path="/partner-register" element={<PartnerRegister />}></Route>
              <Route path="/deposit" element={<Deposit />}></Route>
              <Route path="/reports" element={<Report />}></Route>
              <Route path="/withdraw" element={<Withdraw />}></Route>
              <Route path="/*" element={<Home/>}></Route>
              <Route path="/faq-user" element={<FaqsUser/>}></Route>
              <Route path="/faq-partner" element={<FaqsPartner/>}></Route>
              <Route path="/user-help-center" element={<UserHelpCenter/>}></Route>
              <Route path="/partner-help-center" element={<PartnerHelpCenter/>}></Route>
              <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
              <Route path="/terms-and-service" element={<TermsAndService/>}></Route>
              <Route path="/about-us" element={<AboutUs/>}></Route>
              <Route path="/aml-cft-policy" element={<AmlCftPolicy/>}></Route> 
              <Route path="/risk-assessment-policy" element={<RiskAssessmentPolicy/>}></Route>
              {/* <Route path="/" element={<ConditionRoute type='private'><Home/></ConditionRoute>}></Route> */}
              {/* <Route path="/home" element={<ConditionRoute type='private'><Home/></ConditionRoute>}></Route> */}
              <Route path="/home" element={<Home/>}></Route>
              <Route path="/buy" element={<ConditionRoute type='private'><Buy/></ConditionRoute>}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/register" element={<Register />}></Route>
              <Route path="/counter" element={<Counter/>}></Route>
              <Route path="/payment" element={<ConditionRoute type='private'><StripePaymentElement/></ConditionRoute>}></Route>
              <Route path="/receipt" element={<ConditionRoute type='private'><Receipt /></ConditionRoute>}></Route>
              <Route path="/forgot-password" element={<ForgotPassword />}></Route>
              <Route path="/reset-password/:authToken" element={<ResetPassword />}></Route>
          </Routes> 
          </WagmiConfig>
        <ReduxHelper />
        </Suspense>
      </div>
    )
  }

  export default App; 
  