import { useState } from "react";

const ContactForm = () => {

	const [toName, setToName ] = useState("")
	const [fromName, setFromName] = useState("")
	const [message, setMessage] = useState("")
	const [userMail, setUserMail] = useState("")


	const sendMail = async () => {
		try {

		if(fromName.length <= 1 ) {
          alert("Please enter Valid name")
		}	

		if(message.length <= 1 ) {
			alert("Please enter Valid message")
		  }	
			
		 const myHeaders = new Headers();
		 myHeaders.append("Content-Type", "application/json");
		 
		 var data = {
		   service_id: 'service_rnyst1w',
		   template_id: 'template_79qejbv',
		   user_id: '9Q4yNO7KRHv2V74g1',
		   template_params: {
			   'to_name': 'Indcoinx',
			   'from_name' : fromName,
			   'message' : message,
			   'reply_to' : userMail
			   
		   }
	   };
		 
		 const requestOptions = {
		   method: "POST",
		   headers: myHeaders,
		   body: JSON.stringify(data),
		  
		 };
		 
		 fetch("https://api.emailjs.com/api/v1.0/email/send", requestOptions)
		   .then((response) => response.text())
		   .then((result) => {
			console.log(result)
		    alert("Our team will contact you very soon!")
		})
		   .catch((error) => {
			console.error(error)
		    alert("Something went wrong")
		});
	 
		} catch(e) {
		 console.log("Err", e)
		}
	   }


    return  (
        <div class="contact1">
		<div class="container-contact1">
			<div class="contact1-pic js-tilt" data-tilt>
				<img src="images/img-01.png" alt="IMG"/>
			</div>
 <div>
			<form class="contact1-form validate-form">
				<span class="contact1-form-title">
					Get in touch
				</span>

				<div class="wrap-input1 validate-input" data-validate = "Name is required">
					<input class="input1" type="text" name="name" onChange={(e) => setFromName(e.target.value)} placeholder="Name"/>
					<span class="shadow-input1"></span>
				</div>

				<div class="wrap-input1 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
					<input class="input1" type="text" name="email" onChange={(e) => setUserMail(e.target.value)} placeholder="Email"/>
					<span class="shadow-input1"></span>
				</div>

				<div class="wrap-input1 validate-input" data-validate = "Subject is required">
					<input class="input1" type="text" name="subject" placeholder="Subject"/>
					<span class="shadow-input1"></span>
				</div>

				<div class="wrap-input1 validate-input" data-validate = "Message is required">
					<textarea class="input1" name="message" onChange={(e) => setMessage(e.target.value)} placeholder="Message"></textarea>
					<span class="shadow-input1"></span>
				</div>

			
			</form>
			<div class="container-contact1-form-btn">
					<button onClick={() => sendMail()} class="menu_btn">
				
							Send Email
							
						
					</button>
				</div>
				</div>
		</div>
	</div>
    )
}

export default ContactForm