import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function RiskAssessmentPolicy(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

//   useEffect(() => {
//     setCount(1);
//   }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="menu-link-page" >
     
      <>
  <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:Georgia;\n\tpanose-1:2 4 5 2 5 4 5 2 3 3;}\n@font-face\n\t{font-family:"Noto Sans Symbols";}\n@font-face\n\t{font-family:Gungsuh;}\n@font-face\n\t{font-family:"\\@Gungsuh";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tfont-size:12.0pt;\n\tfont-family:"Times New Roman",serif;}\nh1\n\t{mso-style-name:"Heading 1\\,header";\n\tmso-style-link:"Heading 1 Char\\,header Char";\n\tmargin-top:12.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:3.0pt;\n\tmargin-left:0cm;\n\ttext-align:justify;\n\tline-height:150%;\n\tfont-size:14.0pt;\n\tfont-family:"Times New Roman",serif;\n\tborder:none;\n\tfont-weight:bold;}\np.MsoTitle, li.MsoTitle, div.MsoTitle\n\t{margin-top:24.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0cm;\n\tpage-break-after:avoid;\n\tfont-size:36.0pt;\n\tfont-family:"Times New Roman",serif;\n\tfont-weight:bold;}\nspan.Heading1Char\n\t{mso-style-name:"Heading 1 Char\\,header Char";\n\tmso-style-link:"Heading 1\\,header";\n\tfont-family:"Times New Roman",serif;\n\tborder:none;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-size:12.0pt;}\n /* Page Definitions */\n @page WordSection1\n\t{size:595.0pt 842.0pt;\n\tmargin:2.0cm 2.0cm 2.0cm 70.9pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <p
      className="MsoTitle"
      align="center"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "0cm",
        textAlign: "center",
        lineHeight: "150%"
      }}
    >
      <a name="_heading=h.4zz1pm4ffmfn" />
      <span lang="EN-US">RISK ASSESMENT POLICY</span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-US"
        style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}
      >
        &nbsp;
      </span>
    </p>
    <h1>
      <a name="_heading=h.gjdgxs" />
      <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
        INTRODUCTION
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        This Risk Assessment Policy (Policy) establishes ground for the Company
        s risk management regarding to money laundering and terrorist financing
        risks.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The Policy is the subject of a review by the Company's management board
        at least annually. The proposal for a review and the review of these
        Guidelines may be scheduled more often by the decision of the MLRO.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The words used in the Policy shall be interpreted in accordance with
        definitions provided for in the Guidelines, which annex this Policy is.
      </span>
    </p>
    <h1>
      <a name="_heading=h.30j0zll" />
      <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
        RISK ASSESSMENT PROCEDURE
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The Company shall prepare and regularly update the risk assessment in
        order to identify, assess and analyses the risks of money laundering and
        terrorist financing related to its activities.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The process of risk assessment, executed by Company shall include at
        least the following actions:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks identification;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks analysis;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks evaluation.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The risk assessment and the establishment of the risk appetite shall be
        documented, and the documents shall be updated where necessary.
      </span>
    </p>
    <h1>
      <a name="_heading=h.1fob9te" />
      <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
        THE RISK CATEGORIES
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The Company identifies the risks/threats related to its activities, as
        well as the risks/threats that may arise in the near future, that is
        foreseeable risks/threats, and assesses and analyses their significance
        and impact. The risks/threats are identified and assessed on a
        case-by-case basis as of the moment of the risk assessment and
        separately considering the situation where the Company should take the
        risks to the maximum extent permitted by the risk appetite. The Company
        identifies, assesses and analyses risks of money laundering or terrorist
        financing taking into account the following risk categories:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to customers;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to countries, geographic areas or jurisdictions:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to products, services or transactions, including risks
        relating to new and/or future products, services or transactions:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <a name="_heading=h.3znysh7" />
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to communication, mediation or products, services,
        transactions or delivery channels between the Company and customers.
      </span>
    </p>
    <h1>
      <a name="_heading=h.2et92p0" />
      <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
        THE RISK ASSESMENT SCALE
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The Company shall identify risk factors for the risk categories
        specified in above that increase or decrease the risk of money
        laundering and terrorist financing. The following scale (score) for the
        each identified risk factor grade impact (likelihood x impact) shall be
        used:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          low (1 point)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        assessed risk factor which meets the following:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        has insignificant or does not have affect to the occurrence of risks of
        money laundering or terrorist financing;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        does not increase or increase insignificantly the occurrence of risks of
        money laundering or terrorist financing.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          medium (2 points)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        assessed risk factor which meets the following:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        has medium affect to the occurrence of risks of money laundering or
        terrorist financing;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        increase the occurrence of risks of money laundering or terrorist
        financing.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          high (3 points)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        assessed risk factor which meets at least one of the following:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        has significant affect to the occurrence of risks of money laundering or
        terrorist financing;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        increase the occurrence of risks of money laundering or terrorist
        financing significantly.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          prohibited (4 points)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        assessed risk factor which meets all of the following:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        has significant affect to the occurrence of risks of money laundering or
        terrorist financing;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        increase the occurrence of risks of money laundering or terrorist
        financing significantly;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        does not meet the Company s risk appetite.
      </span>
    </p>
    <h1>
      <a name="_heading=h.tyjcwt" />
      <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
        THE COMPANY S RISK APPETITE
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The following information establishes the Company s risk appetite:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        the Company management board made a decision on establishment of
        business relationships with the Customers from non-EEA countries.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        the Company will provide only services, specified in the Services
        Description (annex of this Policy);
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        the Company will provide only services with the max volume of services
        specified in the Services Description (annex of this Policy);
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks which correspond the Company s risk appetite (the risks assessed
        from low to high) and their assessment are specified in the Customers'
        profiles (annex of this Policy);
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks which Company intends to avoid (the risks assessed as prohibited)
        are specified in the Customers' profiles (annex of this Policy).
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <a name="_heading=h.3dy6vkm" />
      <span
        lang="EN-US"
        style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}
      >
        &nbsp;
      </span>
    </p>
    <h1>
      <a name="_heading=h.1t3h5sf" />
      <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
        THE UPDATE OF RISK ASSESSMENT
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The Company shall update or renew the risk assessment and the related
        documents when necessary, but not less than once per year. The Company's
        is obligated to update or renew risk assessment in the each of following
        cases:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        there are violations of restrictions set by the company s risk appetite;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        the financial performance (for example, profit or turnover) of the the
        company has increased significantly over a short period;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        Customers number significantly increased;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        Customers number with certain risk level significantly increased;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        number of claims from Customers increased significantly;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        number of refusals for business relation with Customers increased
        significantly.;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        number of notifications sent to the authorized bodies increased
        significantly;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        more than 20% of the employees were replaced or removed within 6 months;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        the number of orders from supervisory authorities has increased
        significantly;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        it systems used by the company were changed significantly;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        main service providers of the company were changed;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        new national risk assessment occurred;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        in the other cases if it s required on the opinion of compliance officer
        or management board of the company.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The Company shall update risk assessment and the related documents
        before:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        starting of use new or emerging technologies;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        starting of providing new products or services;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        starting of using non-traditional sales channels;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        starting of using new channels for providing services or products.
      </span>
    </p>
    <h1>
      <a name="_heading=h.4d34og8" />
      <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
        MODEL TO IDENTIFY CUSTOMER S RISK PROFILE
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The Company shall analyze the data obtained during implementation of
        CDD, compare the aforementioned data with risk factors identified for
        each ML/TF risk category and determine the Сustomer s risk profile in
        accordance with the following.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        For the Customer s ML/TF risk assessment the Company uses the following
        risk categories:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to customers;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to countries, geographic areas or jurisdictions:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to products, services or transactions;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-US" style={{ color: "black" }}>
        risks relating to communication, mediation or products, services,
        transactions or delivery channels between the obliged entity and
        customers;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        For the each of aforementioned risk categories following risk score may
        be identified:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          low risk (1 risk point)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        No influential risk factors exist in risks category, the customer itself
        and the customer s activities are transparent and do not deviate from
        the usual activities, i.e. the activities of a reasonable and average
        person, in that field of activity, and there is no suspicion that the
        risk factors as a whole could lead to the realization of the risk of
        money laundering or terrorist financing;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          medium risk (2 risk points)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        One or several risk factors exist in the risks category that deviate
        from the usual activities of a person acting in that field of activity,
        but the activity is still transparent and there is no suspicion that the
        risk factors as a whole could lead to the realization of the risk of
        money laundering or terrorist financing;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          high risk (3 risk points)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        One or several risk factors exist in the risks category that as a whole
        grows suspicion of the transparency of the person and their activities,
        which causes the person to deviate from persons usually acting in that
        field of activity and it is at least possible that money laundering or
        terrorist financing is taking place;
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.0pt",
        marginLeft: "36.0pt",
        textAlign: "justify",
        textIndent: "-18.0pt",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span
        lang="EN-US"
        style={{ fontFamily: '"Noto Sans Symbols"', color: "black" }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <b>
        <span lang="EN-US" style={{ color: "black" }}>
          prohibited risk (4 risk points)
        </span>
      </b>
      <span lang="EN-US" style={{ color: "black" }}>
        {" "}
        Risk is not acceptable by the Company due to risk appetite.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        Each risk category shall be assessed in accordance with risk factors
        identified for the assessing customer s risk profile. The score for
        risks category shall be determined in accordance to higher identified
        risk factor s score in the risk category.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "6.0pt",
        textAlign: "justify",
        lineHeight: "115%",
        border: "none"
      }}
    >
      <span lang="EN-US" style={{ color: "black" }}>
        The risk score of each risk category shall be used in the following
        table to determine the Customer s risk profile.
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-US">&nbsp;</span>
    </p>
    <table
      className="a"
      border={1}
      cellSpacing={0}
      cellPadding={0}
      width={650}
      style={{ borderCollapse: "collapse", border: "none" }}
    >
      <tbody>
        <tr style={{ height: "2.0cm" }}>
          <td
            width={253}
            style={{
              width: "189.6pt",
              border: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "2.0cm"
            }}
          >
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">Risks category/score</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              border: "solid black 1.0pt",
              borderLeft: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "2.0cm"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">Low</span>
            </p>
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">(1)</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.7pt",
              border: "solid black 1.0pt",
              borderLeft: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "2.0cm"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">Medium</span>
            </p>
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">(2)</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              border: "solid black 1.0pt",
              borderLeft: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "2.0cm"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">High</span>
            </p>
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">(3)</span>
            </p>
          </td>
          <td
            width={66}
            colSpan={2}
            style={{
              width: "49.7pt",
              border: "solid black 1.0pt",
              borderLeft: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "2.0cm"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">Prohibited</span>
            </p>
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">(4)</span>
            </p>
          </td>
          <td
            width={68}
            style={{
              width: "51.15pt",
              border: "solid black 1.0pt",
              borderLeft: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "2.0cm"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">Coefficient</span>
            </p>
          </td>
          <td
            width={64}
            style={{
              width: "48.2pt",
              border: "solid black 1.0pt",
              borderLeft: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "2.0cm"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">Result</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "104.35pt" }}>
          <td
            width={253}
            style={{
              width: "189.6pt",
              border: "solid black 1.0pt",
              borderTop: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{ lineHeight: "115%", border: "none" }}
            >
              <span lang="EN-US">Risks relating to customers</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            colSpan={2}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={68}
            style={{
              width: "51.15pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">2</span>
            </p>
          </td>
          <td
            width={64}
            style={{
              width: "48.2pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "104.35pt" }}>
          <td
            width={253}
            style={{
              width: "189.6pt",
              border: "solid black 1.0pt",
              borderTop: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{ lineHeight: "115%", border: "none" }}
            >
              <span lang="EN-US">
                Risks relating to countries, geographic areas or jurisdictions:
              </span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            colSpan={2}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={68}
            style={{
              width: "51.15pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">1</span>
            </p>
          </td>
          <td
            width={64}
            style={{
              width: "48.2pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "104.35pt" }}>
          <td
            width={253}
            style={{
              width: "189.6pt",
              border: "solid black 1.0pt",
              borderTop: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{ lineHeight: "115%", border: "none" }}
            >
              <span lang="EN-US">
                Risks relating to products, services or transactions
              </span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            colSpan={2}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={68}
            style={{
              width: "51.15pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">2</span>
            </p>
          </td>
          <td
            width={64}
            style={{
              width: "48.2pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "104.35pt" }}>
          <td
            width={253}
            style={{
              width: "189.6pt",
              border: "solid black 1.0pt",
              borderTop: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{ lineHeight: "115%", border: "none" }}
            >
              <span lang="EN-US">
                Risks relating to communication, mediation or products,
                services, transactions or delivery channels between the obliged
                entity and customers;
              </span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            style={{
              width: "49.65pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={66}
            colSpan={2}
            style={{
              width: "49.7pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
          <td
            width={68}
            style={{
              width: "51.15pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">1</span>
            </p>
          </td>
          <td
            width={64}
            style={{
              width: "48.2pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "104.35pt"
            }}
          >
            <p
              className="MsoNormal"
              align="center"
              style={{
                textAlign: "center",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "71.55pt" }}>
          <td
            width={490}
            colSpan={5}
            rowSpan={2}
            style={{
              width: "367.5pt",
              border: "solid black 1.0pt",
              borderTop: "none",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "71.55pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">
                The parameters for determining the risk profile of customer are:{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">
                The customer s risk profile is{" "}
                <b>
                  <u>low</u>
                </b>
                , if <b>x &lt; 2</b>
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">
                The customer s risk profile is{" "}
                <b>
                  <u>medium</u>
                </b>
                , if
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "11.0pt", lineHeight: "115%" }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "115%",
                      fontFamily: '"Gungsuh",serif'
                    }}
                  >
                    {" "}
                    2 ≤ x ≤ 3
                  </span>
                </b>
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">
                The customer s risk profile is{" "}
                <b>
                  <u>high</u>
                </b>
                , if <b>x &gt; 3</b>
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">
                The customer s risk profile is{" "}
                <b>
                  <u>prohibited</u>
                </b>
                , if at least one of risks categories has 4 points.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">Exceptions:</span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: "36.0pt",
                textAlign: "justify",
                textIndent: "-18.0pt",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US" style={{ fontFamily: '"Noto Sans Symbols"' }}>
                ●
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span lang="EN-US">
                the Customer s risk level may be determined as low only if no
                one of risks categories scored as high ;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: "36.0pt",
                textAlign: "justify",
                textIndent: "-18.0pt",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US" style={{ fontFamily: '"Noto Sans Symbols"' }}>
                ●
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span lang="EN-US">
                the Customer s risk level shall be determined as high , if at
                least one of risks categories scored as high .
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <b>
                <span lang="EN-US">&nbsp;</span>
              </b>
            </p>
          </td>
          <td
            width={96}
            colSpan={2}
            style={{
              width: "71.95pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "71.55pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <b>
                <span lang="EN-US">Average result (x):</span>
              </b>
            </p>
          </td>
          <td
            width={64}
            style={{
              width: "48.2pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "71.55pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "35.15pt" }}>
          <td
            width={96}
            colSpan={2}
            style={{
              width: "71.95pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "35.15pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <b>
                <span lang="EN-US">Risk level of the customer:</span>
              </b>
            </p>
          </td>
          <td
            width={64}
            style={{
              width: "48.2pt",
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "solid black 1.0pt",
              borderRight: "solid black 1.0pt",
              padding: "0cm 5.4pt 0cm 5.4pt",
              height: "35.15pt"
            }}
          >
            <p
              className="MsoNormal"
              style={{
                textAlign: "justify",
                lineHeight: "115%",
                border: "none"
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr height={0}>
          <td width={227} style={{ border: "none" }} />
          <td width={62} style={{ border: "none" }} />
          <td width={69} style={{ border: "none" }} />
          <td width={62} style={{ border: "none" }} />
          <td width={47} style={{ border: "none" }} />
          <td width={34} style={{ border: "none" }} />
          <td width={87} style={{ border: "none" }} />
          <td width={63} style={{ border: "none" }} />
        </tr>
      </tbody>
    </table>
    <p className="MsoNormal">
      <span lang="EN-US" style={{ fontSize: "11.0pt" }}>
        &nbsp;
      </span>
    </p>
  </div>
</>

  
     </div>
      <FooterCustom/>
    </div>
  );
}
