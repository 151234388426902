import { useNavigate } from "react-router-dom"

const FooterCustom = () => {

    const navigate = useNavigate()

    const reDirectTo = (link) => {
        navigate(link)
    }

    return (
        <footer>
            <div className="footer-content">
            
           
            </div>
            
          
    <div className="footer-bottom">

    <div className="footer-menu">
   
<h3>BlockBridge Ltd</h3>


   <p>  Opening Hours : 10am – 6pm (GMT+2)  <br/>Main Branch: Varšavská 715/36, Vinohrady, 120 00 Prague 2, Czech Republic
   <br/>
email: support@indcoinx.com <br/>
phone: (+886)911219824<br/>
copyright &copy;2023 <a href="#">BlockBridge Ltd</a>   </p>
   </div>


<div className="footer-menu">
    <h3>About Us</h3>
 <ul className="f-menu">
    <li onClick={() => reDirectTo('/about-us')}><p>About Us</p></li>
    <li onClick={() => reDirectTo('/contact')}><p>Contact Us</p></li>
 </ul>
</div>


<div className="footer-menu">
    <h3>Policies</h3>
 <ul className="f-menu">
    <li onClick={() => reDirectTo('/terms-and-service')}><p>Terms and Services</p></li>
    <li onClick={() => reDirectTo('/privacy-policy')}><p>Privacy Policy</p></li>
    <li onClick={() => reDirectTo('/risk-assessment-policy')}><p>Risk Assesment Policy</p></li>
    <li onClick={() => reDirectTo('/aml-cft-policy')}><p>AML CFT Policy</p></li>
 </ul>
</div>

{/* <div className="footer-menu">
    <h3>Help Center</h3>
 <ul className="f-menu">

    <li onClick={() => reDirectTo('/partner-help-center')}><p>Partner Help Center</p></li>
    <li onClick={() => reDirectTo('/user-help-center')}><p>User Help Center</p></li>

 </ul>
</div> */}

<div className="footer-menu">
    <h3>FAQS</h3>
 <ul className="f-menu">

    <li onClick={() => reDirectTo('/faq-partner')}><p >FAQs for partner</p></li>
    <li onClick={() => reDirectTo('/faq-user')}><p href="">FAQs for user</p></li>
 </ul>
</div>

    </div>
    {/* <ul className="socials">
   <li><a href="#"><i className="fab fa-telegram-plane"></i></a></li>
   <li><a href="#"><i className="fab fa-twitter"></i></a></li>
   <li><a href="#"><i className="fab fa-discord"></i></a></li>
   <li><a href="#"><i className="fab fa-medium-m"></i></a></li>
  
    </ul> */}
        </footer>
    )
}

export default FooterCustom