import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function Home(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();
  

  const widgetRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=83860f1a-bf0d-434d-8eef-60743b545a28';
    script.async = true;
    script.onerror = () => {
      console.error('Error loading Zendesk widget script');
    };
    widgetRef.current.appendChild(script);
  }, []);



  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setCount(1);
  }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})

const redirectTo = (link) => {
navigate(link)
}

  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
       <div ref={widgetRef}>
      {/* Your app content */}
      <div id="zendesk_widget">{/* Zendesk widget will be rendered here */}</div>
    </div>
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={10} className="">
              <div className="">
                {/* <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /> */}
                {/* <h1>Connect your token to withdraw your CT tokens in your wallet</h1> */}
                <h1 style={{color : "white"}}>Revolutionize payments with Infiniteway</h1>
                {/* <p>Receive, send, store, exchange, and pay in cryptocurrency with a security audited crypto wallet.</p> */}
                <p style={{color : "white"}}>Pay, receive, buy and store with cryptocurrency with a security audited payment gateway—unlock the full potential of digital currencies today.</p>
                {(user.address && (user.type.length > 2)) ?
                <>
                  <button className="primary_btn" onClick={handlewithdraw}>withdraw Your Token</button> 
                  <button className="primary_btn" style={{"margin-left":"10px"}} onClick={handlelink} >View Report</button>
                  </>:
                  <>
                   <button className="primary_btn" onClick={() => redirectTo('/login')}>Join now</button>
                  
                  </>
                  }

              </div>
            </GridItem>
          </GridContainer>
        </div>

       

      </div>

      <div style={{padding : "200px"}}>

      <div className="about-us-body-two">
        <img
        className="home-banner-img"
        style={{flex : "1",
        width: "100%",
        height : "600px"
      }}
      alt="banner"
      src="images/img-home-01.png"
      />
      <div style={{flex : "1", fontSize : "20px"}}>
        <h1>
        Our Partners
          </h1>  
          <p>
          As a partner, you'll gain access to our innovative crypto payment gateway, empowering you to seamlessly integrate INW tokens as a payment method and expand your customer base. With our user-friendly platform and dedicated support, you can enhance the payment experience for your customers while staying ahead in the rapidly evolving digital landscape. Whether you're an e-commerce platform, a service provider, or a brick-and-mortar business, partnering with us opens doors to new opportunities and positions you at the forefront of the future of finance
</p>
<button onClick={() => redirectTo('/partner-register')} className="menu_btn">Join now</button>
        </div>
     

     </div>

     <div className="about-us-body-two">
        
      <div style={{flex : "1",  fontSize : "20px"
        }}>
        <h2 >
        Our Users
          </h2>  
          <p>
          As a user, you'll experience the convenience, security, and flexibility of our crypto payment gateway firsthand. Seamlessly pay for goods and services using INW tokens, or effortlessly purchase tokens with your credit or debit card if needed. With our intuitive platform and robust security measures, you can trust that your transactions are safe and your privacy is protected. Whether you're a seasoned crypto enthusiast or new to the world of digital finance, Infiniteway is here to simplify your payment experience and empower you to transact with confidence.
</p>
<button onClick = {() => redirectTo('/Register')} className="menu_btn">Join now</button>
        </div>

        <img
        style={{flex : "1",
        width: "100%",
        height : "600px",

     }}
      alt="banner"
      src="images/img-home-02.png"
      />

     </div>

      </div>
    
      <FooterCustom/>
    </div>
  );
}
