import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

//   useEffect(() => {
//     setCount(1);
//   }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="menu-link-page" >
     
     <div className="about-us-container">
      <div className="about-us-header">
      <h4>About Us</h4>
      <p>Welcome to Infiniteway—where the future of finance begins.</p>
      <h3>
      Blockbridge Ltd.
      </h3>
      <p>
      At Blockbridge, we are dedicated to revolutionizing the way people transact in the digital age. Founded with a vision to empower individuals and businesses with seamless and secure payment solutions, we have established ourselves as a leading player in the crypto payment gateway industry.
      </p>
     
      </div>

      <img
      alt="banner"
      style={{
        width: "100%",
        height : "600px",
        marginTop: "100px"
      }}

      src="images/img-aboutus-01.png"
      />

      <div className="about-us-body-one">
             <div>
              <h4>Out Mission</h4>
              <p>
              To bridge the gap between traditional financial systems and the emerging world of cryptocurrencies, making it easier for everyone to embrace the future of finance. We believe in the transformative potential of digital currencies to democratize finance, foster economic inclusion, and drive innovation across industries.
              </p>
              </div>
              <div>
                <h4>Our Vision</h4>
                <p>We&nbsp;re revolutionizing global finance with inclusivity, innovation, and empowerment. Our vision is a borderless future where technology connects people seamlessly, individuals control their financial destinies, and transactions are efficient, transparent, and responsible. Join us in shaping a brighter, inclusive, and sustainable future.</p>
              </div>
              <div>
                <h4>
                  Our Impact
                </h4>
                <p>
                It&nbsp;s about fostering inclusivity, empowerment, and sustainability worldwide. Through innovative solutions and ethical practices, we&nbsp;re breaking down barriers, enabling individuals to thrive, and creating a more equitable and sustainable future for all.
                  </p>
              </div>

        </div>

        <div className="about-us-body-two">
        <img
        style={{flex : "1",
        width: "100%",
        height : "400px"
      }}
      alt="banner"
      src="images/img-aboutus-02.png"
      />
      <div style={{flex : "1"}}>
        <h4>
        Acceptance/Payments with crypto
          </h4>  
          <p>
          Our platform enables partners to effortlessly integrate INW tokens as a payment method, providing them with greater flexibility and expanding their customer base.
<br/>
Users can utilize their INW tokens to seamlessly pay for goods and services, enjoying fast and secure transactions with low fees
</p>

        </div>
     

     </div>

     <div className="about-us-body-two">
        
      <div style={{flex : "1"
        }}>
        <h4>
        Token Purchase with Credit/Debit Card
          </h4>  
          <p>
          For users who do not possess INW tokens, we offer a convenient solution to purchase tokens using credit or debit cards, ensuring accessibility to the crypto ecosystem.
</p>

        </div>

        <img
        style={{flex : "1",
        width: "100%",
        height : "400px",

     }}
      alt="banner"
      src="images/img-aboutus-03.png"
      />

     </div>

     </div>
     <img
       style={{
        width : "100%",
        height  : "300px"
       }}
      alt="banner"
      src="images/img-aboutus-04.png"
      />
     </div>
      <FooterCustom/>
    </div>
  );
}
