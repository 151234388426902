import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function TermsAndService(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

//   useEffect(() => {
//     setCount(1);
//   }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
       <div className="menu-link-page" >
     
       <>
  <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh4\n\t{margin-top:14.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:12.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#666666;\n\tfont-weight:normal;}\nh5\n\t{margin-top:12.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#666666;\n\tfont-weight:normal;}\np.MsoTitle, li.MsoTitle, div.MsoTitle\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:3.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:26.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <p className="MsoTitle">
      <a name="_r2n7opc108yh" />
      <span lang="EN-GB">Infiniteway CPG Terms and Conditions</span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0cm",
          marginBottom: "9.0pt",
          marginLeft: "0cm",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Blockbridge Ltd (the "Organization"), a privately owned business
          situated at Var avsk 715/36, Vinohrady, 120 00 Prague 2, Czech
          Republic.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0cm",
          marginBottom: "9.0pt",
          marginLeft: "0cm",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          These Partner Terms of Service ("the Terms'') govern your use of the
          products, services, or any otherfeatures, technologies, or
          functionalities (the "Services'') provided by Infiniteway.
          (Infiniteway, we, our, or us) through Infiniteway website, API. The
          terms "you" and "your" refer to the partner to which we will be
          providing the Services and the person signing below or otherwise
          agreeing to the Terms on behalf of the partner. Please read the Terms
          carefully; by using the Services, you agree to the Terms and confirm
          that you accept them.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_5wlz6wrrefai" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            The Services
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "9.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          We handle payments using cryptocurrencies. We give you the ability to
          process crypto payments that you receive from your customer (User) and
          accept cryptocurrencies as payment for goods or services. We do not
          operate a crypto exchange. Only companies that offer goods or
          services, or accredited nonprofits that take donations, are eligible
          to use our services. By utilising the Services, you grant us
          permission to operate as your agent, enabling us to accept, keep, and
          distribute money on your behalf and to take any and all actions that
          we deem necessary to deliver the Services and adhere to applicable
          legal requirements. Payment made by the User to Infiniteway shall be
          regarded as having been made directly to you and will, to the extent
          of the payment, terminate the User's outstanding obligations.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_9buq36j14qal" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            The gatherings concur as takes after
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Partner's Authority and Capacity: Trader attests that the person
          signing this agreement is at least eighteen years old, possesses the
          necessary legal expertise to accept it, and that Partner will be bound
          by that acceptance.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Installment Gateway Service:- The Service comprises an installment
          door, a dashboard (at present found at , and the Service APIs, which
          together empower dealers to acknowledge INW token and other advanced
          monetary forms as installment from buyers of merchandise or
          administrations. The vendor will use the Service in accordance with
          this Agreement's terms and conditions, including the payment of fees.
          Shipper grants permission to the Company to obtain virtual currency
          standards, trade and distribute them for the advantage of the Partner,
          and undertake any other actions the Company deems necessary or
          enticing in order to provide the Service or to comply with applicable
          laws.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_phm4pa5lmih7" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            1. Partner's Account
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <h5
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <a name="_yl6qfp61dfc7" />
        <span
          lang="EN-GB"
          style={{ fontSize: "10.0pt", lineHeight: "115%", color: "black" }}
        >
          Generally
        </span>
      </h5>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "15.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          To use the Services, you must register an Infiniteway Partners
          account. We will request contact details from you throughout the
          account registration process, including your name, email address, and
          details about the ultimate beneficial owner or the highest-ranking
          member of the company. Additionally, we will need information about
          your firm, such as its legal name, physical address, and website. When
          opening an account, you must supply accurate and comprehensive
          information. Additionally, we might get information about you from
          outside sources including identity verification agencies and credit
          bureaus. Should you fail to furnish us with correct, comprehensive,
          and adequate information, we have the right to refuse your account
          registration or to subsequently terminate your Infiniteway Partners
          account.
        </span>
      </p>
      <h5
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <a name="_pdvyvrnk5r8w" />
        <span
          lang="EN-GB"
          style={{ fontSize: "10.0pt", lineHeight: "115%", color: "black" }}
        >
          Guarding your Password
        </span>
      </h5>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "15.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Selecting a password is a step in the account registration process. It
          is your responsibility to keep your password and account access
          details private. Any action taken using your password or account is
          entirely your responsibility. If your password or account is used
          without authorization, or if there is any other security breach, do
          let us know right once. If you disclose your password to third
          parties, we disclaim all liability for any losses you might suffer as
          a result of someone else using your account or password either
          secretly or with your knowledge.
        </span>
      </p>
      <h5
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <a name="_cw1htcqkria9" />
        <span
          lang="EN-GB"
          style={{ fontSize: "10.0pt", lineHeight: "115%", color: "black" }}
        >
          Transaction Volume Limits
        </span>
      </h5>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "15.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Partners are subject to daily and annual processing limits on
          transactions with Infiniteway. There are two options available to you
          when creating an Infiniteway account: a free plan and a premium plan.
          Transaction capabilities should be chosen at Infiniteway's discretion
          and in accordance with the company's risk assessment.
        </span>
      </p>
      <h5
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <a name="_561l2t9qrfly" />
        <span
          lang="EN-GB"
          style={{ fontSize: "10.0pt", lineHeight: "115%", color: "black" }}
        >
          Use of Third-Party Services
        </span>
      </h5>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "15.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          While using the Infiniteway website or the Services, you may be
          offered services, products, and promotions provided by third parties.
          You acknowledge that using these third-party services is at your own
          risk and that you are in charge of reading, comprehending, and abiding
          by the relevant terms and conditions. We explicitly disavow all
          responsibility for the third-party services and are not in charge of
          how well they are performed.
        </span>
      </p>
      <h5
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <a name="_hqdhyneqwdvw" />
        <span
          lang="EN-GB"
          style={{ fontSize: "10.0pt", lineHeight: "115%", color: "black" }}
        >
          Security
        </span>
      </h5>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "15.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          To protect your information against unintentional loss and
          unauthorised access, use, alteration, or disclosure, we have put
          security measures in place. You understand that you give information
          at your own risk and that we are unable to ensure that unauthorised
          individuals will never obtain access to it.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_y9c5hsh3g99v" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            2. Data Collection, Privacy, and Security
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          We might offer third parties access to information about you and your
          Infiniteway account in order to deliver the Services. When you create
          a coinremitter partner account, you provide us with all the
          information that can be shared. Please read our Disclaimer &amp; Risk
          Warning as there is always a risk involved.{" "}
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_pthuysdov16g" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            3. Termination and Suspension
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "15.0pt",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          At our discretion, we may close your account and give you notice by
          phone or email. If we believe you have violated these Terms, that you
          represent an unacceptable risk of fraud to us, or that you have
          provided any false, incomplete, inaccurate, or misleading information,
          we may also suspend your access to the Services. In the event that we
          close your account or suspend it, we won't be responsible for any
          losses you suffer. Furthermore, coinremitter has the authority to
          suspend your account and stop processing if your partner account is
          not used for more than a year.
        </span>
      </p>
      <h5
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <a name="_jicllz4xhnmn" />
        <span
          lang="EN-GB"
          style={{ fontSize: "10.0pt", lineHeight: "115%", color: "black" }}
        >
          And what kind of business or website cannot get access to coinremitter
          services.
        </span>
      </h5>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm",
        marginLeft: "18.0pt",
        marginRight: "0cm"
      }}
    >
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Any terrorist organisation, any organisation that opposes humans, any
          company, or any website that is either directly or indirectly involved
          in criminal activity.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Any website that is a fraud or scam
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </span>
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Any website that uses multilevel marketing (MLM) to advertise its
          business
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "18.0pt",
          textIndent: "-18.0pt",
          border: "none",
          padding: "0cm",
          paddingTop: "0cm"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Any website that offers its customers unrealistic returns (ROI)
        </span>
      </p>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          If we discover any suspicious behaviour on your website or through
          your website, we may request further information. If this happens, we
          won't notify you in advance before suspending your account or taking
          legal action against you and your company.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_uewv15dlfwg0" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            4. Fees
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Partner Fees: - The partner has to be charged 0.2% of the fees
          collected.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_53urotbhqsrf" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            5. Settlement
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          In accordance with your preference settings, we will confirm the
          User's payments and submit the remaining amount to your accounting
          ledger. A settlement in your local currency cannot be issued to you.
          You take up the currency's volatility risks when you decide to settle.
          For instance, you take on the volatility risk associated with the
          value of the INW token if you decide to settle in INW token.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_phtfs4gf4gti" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            6. Charge Changes
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          The Company may alter fees and other charges related to its services
          in response to fluctuations in the value of cryptocurrencies; partners
          will not be notified in advance of such changes. Partner's
          acknowledgement of such modifications is demonstrated by Vendor's
          acceptance of an alternative Fee Schedule or by Partner's continued
          use of the Service.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_nd4wnudwf99w" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            7. Intellectual Property
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          All of Infiniteway's trademarks, names, and logos are still our
          property, and we maintain all rights, title, and interest in and to
          the Content. Thus, Infiniteway grants Partner a limited,
          non-selective, non-transferable licence to use our content with our
          services, according to the conditions and provisions of this
          Agreement..
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_od2kb43a6mdx" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            8. Confidential Information
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", border: "none", padding: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Confidential Information (the Receiving Party) from the other party
          (the Disclosing Party) agrees to: (a) hold the Confidential
          Information of the Disclosing Party in strict certainty and use the
          same degree of care (or at the very least reasonable care) to ensure
          the privacy of its secret data; (b) not use any Confidential
          Information of the Disclosing Party for any purpose outside the scope
          of this Agreement.; and (c) other than as generally approved in
          writing by the disclosing party, to limit access to the Disclosing
          Party's Confidential Information to employees and temporary employees
          of the Disclosing Party and its Affiliates who need access for
          purposes consistent with this Agreement and who have agreed to
          classification agreements with the Receiving Party that include
          insurances no less stringent.
        </span>
      </p>
    </div>
    <div
      style={{
        border: "none #E5E7EB 1.0pt",
        borderBottom: "solid #E5E7EB 1.0pt",
        padding: "0cm 0cm 0cm 0cm"
      }}
    >
      <h4
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "2.0pt",
          marginLeft: "0cm",
          pageBreakAfter: "auto",
          border: "none",
          padding: "0cm"
        }}
      >
        <a name="_ecr5kc41vb6z" />
        <b>
          <span
            lang="EN-GB"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "black" }}
          >
            9. Limitation of Liability
          </span>
        </b>
      </h4>
    </div>
    <div style={{ border: "none #E5E7EB 1.0pt", padding: "0cm 0cm 0cm 0cm" }}>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "12.0pt",
          border: "none",
          padding: "0cm",
          paddingBottom: "0cm",
          borderBottom: "0cm none #E5E7EB"
        }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Under any theory of liability or cause of action, whether in tort
          (including negligence), contract, or otherwise, the company or any of
          its affiliates or vendors (or any officers, directors, employees, or
          agents of the company or its affiliates or vendors) be liable for any
          indirect, incidental, consequential, special, or exemplary damages
          (however or whenever arising), including, without limitation, damages
          for lost revenue, lost profits, anticipated profits, lost business or
          injury to business reputation, cost of procurement of substitute
          services; and regardless of whether it has been advised of the
          possibility of such damages;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ border: "none", padding: "0cm", paddingTop: "0cm" }}
      >
        <span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
          Whether based on contract, tort (including negligence), or another
          legal theory, the company's total liability to the partner under this
          agreement or with regard to any goods or services the company provides
          exceeds the total amount of money the company was paid in total for
          serving the partner during the six months prior to the date the claim
          was made.
        </span>
      </p>
    </div>
    <p
      className="MsoNormal"
      style={{
        marginTop: "10.0pt",
        marginRight: "0cm",
        marginBottom: "10.0pt",
        marginLeft: "0cm"
      }}
    >
      <b>
        <span
          lang="EN-GB"
          style={{
            fontSize: "10.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto"
          }}
        >
          &nbsp;
        </span>
      </b>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
  </div>
</>

  
     </div>
      <FooterCustom/>
    </div>
  );
}
