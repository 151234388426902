/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";
const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const [count, setCount] = useState(1);
  useEffect(() => {
    // document.title = `You clicked ${count} times`;
    // console.log("Count: " + count);
    setCount(1);
  }, [count]);

  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>        
        <div className="footer_bottom_home">
          <div className="footer_social_links">
            <ul>
              <li><a href="https://web.telegram.org/z/" target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
              <li><a href="https://twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a></li>
              <li><a href="https://discord.com/" target="_blank"><i class="fab fa-discord"></i></a></li>             
              <li><a href="https://medium.com/" target="_blank"><i class="fab fa-medium-m"></i></a></li>
            </ul>
          </div>
          <div className="copyright_txt">
            <p className="mb-0">© 2021 Copyright Shiro.  All rights reserved.</p>
            <ul>
              <li><a href="#">WhitePaper</a></li>
              <li><a href="#">Terms of Service</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
