import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import config from '../config/config'
import { toastAlert } from "../helper/toastAlert";
import { paymentdetails,updatepaymentintent } from "../Api/payment-gateway";
import {verifyHash} from "../Api/comman"
import {getipdetails} from "../Api/comman";
import { useSelector, useDispatch } from 'react-redux'
import { addUser,removeUser } from "../redux-store/reducer";

let initaddress = {
  "name": "",
  "address": {
      "line1": "",
      "line2": null,
      "city": "",
      "country": "",
      "postal_code": "",
      "state": ""
  }
}

export default function CheckoutForm(props) {
  const { clientSecret,userId,tokens,payment_intent_id } = props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state)=>state.user)
  const [email, setEmail] = useState('');
  const [userAddress, setuserAddress] = useState(initaddress);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [payment_status, setpayment_status] = useState(false);
  const [client_Secret, setclient_Secret] = useState()
  const [startpayment, setstartpayment] = useState(false);
  const [verifyamt, setverifyamt] = useState('');
  const getdata=(async()=>{
    var secrethash = localStorage.getItem('weuueiq');
    if(secrethash){
      var dataobj={hash:secrethash}
      let {result,success,message}= await verifyHash(dataobj)
      console.log(result,success,'result,success')
      setverifyamt(result.data.amount)
    }
  
   
  })

  useEffect(() => {
  
    getdata()
   
   
    
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    setMessage("")
    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    if (!clientSecret) {
      return;
    }
    setclient_Secret(clientSecret);
    stripe.retrievePaymentIntent(clientSecret).then(async ({ paymentIntent }) => {
      if(startpayment){
      switch (paymentIntent.status) {
        case "succeeded":
          console.log(paymentIntent, "paymentIntent...123");
          let {ip} = await getipdetails();
          let userdata = {"email":email,"userId": userId,"tokens":tokens,"address":userAddress.address,"type":"Buy"}
          let reqdata ={"info":ip,"payment": paymentIntent,"user":userdata}
          let {result,success} = await paymentdetails(reqdata);
          setIsLoading(false);
          if(success){
            dispatch(addUser({
              balance: result,
            }))
          setMessage("Payment succeeded!");
          toastAlert("success", "Payment succeeded!")
          }else{
            setMessage("Payment Failed!");
            toastAlert("error", "Error on server!")
          }
          var hash = localStorage.getItem('hash');
          // if(user&& user.url){
          // window.location.replace(`${user.url}?payment_intent=${paymentIntent.id}&status=succeeded`);
          // }
          console.log(verifyamt==localStorage.getItem("amount"),verifyamt,localStorage.getItem("amount"),'smtttt')
          if(hash && (verifyamt==localStorage.getItem("amount"))){
            var amt =  localStorage.getItem("amount");
            navigate('/receipt', {
              state: {
                payamount: amt,
              }
            });
          }
          else{
            navigate('/home');
          }
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    }
    });
  }, [stripe, payment_status, client_Secret]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setstartpayment(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    // let reqdata ={secret:payment_intent_id};
    // let {success,message} = await updatepaymentintent(reqdata);
    // if(!success){
    //   toastAlert('error',"Error on details update");
    //   return false;
    // }
    const paymentElement = elements.getElement('payment');
    paymentElement.update(
      {shipping:userAddress});
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: config.fronturl,
      },
      redirect: "if_required",
    });
    setpayment_status(true)
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    console.log(error,'error131')
    if (error && (error?.type === "card_error" || error?.type === "validation_error")) {
      setMessage(error.message);
      toastAlert('error', error.message);
    } else if(error){
      setMessage("An unexpected error occurred.");
     // toastAlert('error', "An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "accordion"
  }

  const addressElementOptions = {
    mode: 'billing',
  }


  const handleEmail=((e)=>{
    let {email} = e.value;
  setEmail(email)
  })

  const handleAddress=((e)=>{
    let {name} = e.value;
    let addressdata ={...userAddress,...e.value}
    setuserAddress(addressdata);
    console.log(name,"name...123",e.value,"value");
  })
  console.log(userAddress,"useraddress");
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(event)=>handleEmail(event)}
      />
      <AddressElement onChange={(event) =>handleAddress(event)} id="address-element" options={addressElementOptions} />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="primary_btn">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}