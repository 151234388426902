import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import FooterCustom from "../components/Footer/FooterCustom.js";
import ContactForm from "../components/ContactFor.jsx";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function FaqsUser(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // useEffect(() => {
  //   setCount(1);
  // }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
       <div className="menu-link-page" >
     
       <>
  <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1\n\t{margin-top:20.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:20.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh2\n\t{margin-top:18.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:16.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh3\n\t{margin-top:16.0pt;\n\tmargin-right:0cm;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0cm;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:14.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#434343;\n\tfont-weight:normal;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <h1
      style={{
        margin: "0cm",
        lineHeight: "120%",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_bngq61fjxs06" />
      <span
        lang="EN-GB"
        style={{ fontSize: "24.0pt", lineHeight: "120%", color: "#11193B" }}
      >
        How to create a Wallet?
      </span>
    </h1>
    <h2>
      <a name="_wokdy3t54bmc" />
      <span lang="EN-GB">MetaMask</span>
    </h2>
    <p className="MsoNormal">
      <span lang="EN-GB">
        MetaMask is a very popular browser-based wallet plugin that supports
        ERC20 (Ethereum network) and BEP2 &amp; BEP20 (Binance Chain and BNB
        Smart Chain networks).
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">
        ​<b>Pros:</b>
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">Open source for auditability</span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">WEB3 capable on BscScan</span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">A strong history of security and reliability</span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        A large amount of how-to guides and resources online
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">Many tools and custom settings</span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">Offers ETH purchases with Transak</span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">Supports a very large number of languages</span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span lang="EN-GB">
        Is also available a browser plugin for desktop devices
      </span>
    </p>
    <p className="MsoNormal">
      <b>
        <span lang="EN-GB">Cons:</span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">Longer setup time than other options</span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        Additional steps needed to use with BNB Smart Chain and PancakeSwap
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span lang="EN-GB">
        Extra information and settings may confuse beginners
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">
        <a href="https://metamask.io/download.html">
          <b>
            <span style={{ color: "#1155CC" }}>Download MetaMask</span>
          </b>
        </a>{" "}
        (Automatically detects device)
        <a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain">
          <span style={{ color: "windowtext", textDecoration: "none" }}> </span>
        </a>
      </span>
    </p>
    <h2>
      <a name="_ad1124jmw4tk" />
      <span lang="EN-GB">WalletConnect</span>
    </h2>
    <p className="MsoNormal">
      <span lang="EN-GB">
        Walletconnect is one such protocol and probably a viable open source
        solution to securely connect wallets and dapps. Through relay payloads,
        a remote connection can be established between the two devices and/or
        apps in a secured manner through symmetrical encryption. The connection
        can be initiated by one device displaying the QR code and the
        counter-party device can approve that connection to establish the bridge
        between the two and can make it inter-operable. Additionally, it also
        includes an optional push server to allow Native applications to notify
        the user of incoming payloads for establishes connections.
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">
        ​<b>Pros:</b>
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        Without downloading any desktop wallet or an extension or plugin, you
        can interact with the dapp. Walletconnect makes it possible for the two
        devices to become inter-operable.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        It is secured as the connection is established through symmetrical
        encryption.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        It makes the blockchain exposure to a new user more friendly and gives
        an easy access to interact with the dapps. That means it lowers the
        barriers to entry and can enhance mass adoption.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        In order to interact with a dapp or a specific use-case it may not
        necessarily require for a user to download Metamask plugin or any such
        extension. With the mobile wallets also, it can be made possible through
        Walletconnect.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        It is open-source, so any one can participate and make the development
        even better than the current one.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-GB">
        The application is available in both Android and iOS.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span lang="EN-GB">It does not require any hardware or software.</span>
    </p>
    <p className="MsoNormal">
      <b>
        <span lang="EN-GB">Cons:</span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span lang="EN-GB">
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span lang="EN-GB">Timely updates in the forum is needed.</span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">
        <a href="https://walletconnect.com/">
          <b>
            <span style={{ color: "#1155CC" }}>Download WalletConnect</span>
          </b>
        </a>
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_2xw4moau1kyw" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        I paid too much. What can I do to the excess amount?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If you paid too much, you may keep it as your balance in Infiniteway
        CPG, which can be used in your next payment. Or you may{" "}
      </span>
      <span lang="EN-GB">
        <a href="#_orjrkxynl4ho">
          <span
            style={{
              fontSize: "11.5pt",
              lineHeight: "115%",
              color: "#1155CC",
              background: "white"
            }}
          >
            withdraw your balance
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        {" "}
        to your crypto wallet.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_yf3xcv763p4f" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        I paid too little. What should I do?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If you underpaid your invoice, you will see the amount of cryptocurrency
        in your Infiniteway CPG balance and will redirect you to the buy tokens
        page. Simply make the next transaction to cover the underpaid amount.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Once you make sufficient payment, you will need to confirm your payment.
        After your transaction is confirmed, your payment will be completed.
      </span>
    </p>
    <h1
      style={{
        marginTop: "23.0pt",
        marginRight: "0cm",
        marginBottom: "15.0pt",
        marginLeft: "0cm",
        lineHeight: "125%",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_y79dc24uuiwr" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "125%",
          color: "#11193B",
          background: "white"
        }}
      >
        My invoice has expired or been canceled after I made a payment. What
        now?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Your tokens will be kept as your balance in Infiniteway. You may use
        them in your next payment or{" "}
      </span>
      <span lang="EN-GB">
        <a href="#_orjrkxynl4ho">
          <span
            style={{
              fontSize: "11.5pt",
              lineHeight: "115%",
              color: "#1155CC",
              background: "white"
            }}
          >
            withdraw
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        {" "}
        them to your crypto wallet.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_as9yap3xx0au" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How do I pay through Infiniteway CPG? Can I pay with anything else than
        INW?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        No. You can pay only with INW token under Binance Smart Chain.{" "}
      </span>
      <span lang="EN-GB">
        <a href="#_3q21gx5cukkw">
          <span
            style={{
              fontSize: "11.5pt",
              lineHeight: "115%",
              color: "#1155CC",
              background: "white"
            }}
          >
            Read here
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        {" "}
        for step-by-step instructions on how to pay through Infiniteway CPG. If
        you don t have any INW but still want to use them for shopping, you can
        purchase them{" "}
      </span>
      <span lang="EN-GB">
        <a href="#_mcoiv9vqtb9x">
          <span
            style={{
              fontSize: "11.5pt",
              lineHeight: "115%",
              color: "#1155CC",
              background: "white"
            }}
          >
            here
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        .
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_resnjb3xh1qy" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Why does Infiniteway CPG charge withdraw fee?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        After the funds for your purchase are received, they are moved several
        times within Infiniteway CPG's infrastructure before they reach the
        final beneficiary. Each time the funds are moved, Infiniteway CPG pays a
        dynamic fee to the miners to get the transaction confirmed on the
        blockchain, which varies depending on the network load. To cover these
        network costs and ensure timely processing of payments, we levy these
        costs on the users.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_jtun2ap5jsk" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        When will my order be shipped/delivered?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Because we are a payment processor that provides 3rd party services for
        merchants, we do not hold any information about the status of your
        order. As a result, we are not able to determine the time frame. To find
        out about the order processing, shipping, handling, etc. of your
        item/service/merchandise, please contact your seller directly.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_dx2r21btdtav" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Does Infiniteway CPG support alternative payments like credit cards or
        PayPal?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        You cannot pay for goods and services directly using credit/debit card,
        PayPal, or other similar payment methods under Infiniteway CPG. However,
        you can buy INW tokens with credit/debit cards, Moonpay, Paypal, PayU
        and AirWalletX here, and pay with INW tokens.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_iuogk1cx2ykw" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        What payment methods can I use to buy cryptocurrencies?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        At the moment, we offer several options, all of which allow purchasing
        different cryptocurrencies and charge different fees. Here is a list of
        available options:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Moonpay
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Paypal
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        PayU
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Credit/Debit Card
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        AirWalletX
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "0cm"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Learn how to buy cryptocurrencies{" "}
      </span>
      <span lang="EN-GB">
        <a href="#_mcoiv9vqtb9x">
          <span
            style={{
              fontSize: "11.5pt",
              lineHeight: "115%",
              color: "#1155CC",
              background: "white"
            }}
          >
            here
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        .
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_me48qj8vtx5y" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How can I change the email address associated with my Infiniteway CPG
        account?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        The email address will be taken from your ecommerce website which cannot
        be changed. If you changed your email address in your ecommerce website,
        you ll have to register again with your new email address in Infiniteway
        crypto payment gateway.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        If you have balance in your old account, you ll need to{" "}
      </span>
      <span lang="EN-GB">
        <a href="#_orjrkxynl4ho">
          <span
            style={{
              fontSize: "11.5pt",
              lineHeight: "115%",
              color: "#1155CC",
              background: "white"
            }}
          >
            withdraw
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        {" "}
        your tokens and deposit them in your new account.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_wk9k916h8fpm" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How long does it take to process a withdrawal?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        For security reasons, Infiniteway CPG processes withdrawals after a
        manual review which occurs twice a day and could take up to 24 hours.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "13.0pt",
        marginLeft: "0cm",
        lineHeight: "112%",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_s8ffvr6ivonu" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "112%",
          color: "#11193B",
          background: "white"
        }}
      >
        Create your account
      </span>
    </h1>
    <p className="MsoNormal" style={{ background: "white" }}>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Let's get started! Follow these simple steps to create your account:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        1.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Go to
      </span>
      <span lang="EN-GB">
        <a href="https://cryptopay.me/">
          <span
            style={{
              fontSize: "12.0pt",
              lineHeight: "152%",
              color: "#11193B",
              background: "white",
              textDecoration: "none"
            }}
          >
            {" "}
          </span>
        </a>
        <a href="https://www.indcoinx.com/Register">
          <span
            style={{
              fontSize: "12.0pt",
              lineHeight: "152%",
              color: "#1155CC",
              background: "white"
            }}
          >
            https://www.indcoinx.com/Register
          </span>
        </a>
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        2.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Enter your email address.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        3.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Click [Send OTP].
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        4.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        You will then receive an email with OTP.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        5.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Enter the OTP that is received in your email.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        6.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Enter the password you'd like to use for your account.
        <br />
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          fontFamily: "Roboto",
          color: "red",
          background: "white"
        }}
      >
        Note: Password should contain at least one uppercase, at least one
        lowercase, at least one number, at least one special character and
        minimum 6 and maximum 18
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        7.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Confirm your password
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "47.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        8.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Click [Register]
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "0cm",
        lineHeight: "152%"
      }}
    >
      <b>
        <i>
          <span
            lang="EN-GB"
            style={{ fontSize: "12.0pt", lineHeight: "152%", color: "#11193B" }}
          >
            <img
              border={0}
              width={453}
              height={561}
              id="image4.png"
              src="images/img-faquser-1.png"
            />
          </span>
        </i>
      </b>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        Your account has been created!
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_3q21gx5cukkw" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How to make a payment using Infiniteway CPG?
      </span>
    </h1>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B",
          background: "white"
        }}
      >
        You ll be directed to the log in page for the log in process. If you do
        not have an account, register{" "}
      </span>
      <span lang="EN-GB">
        <a href="#_s8ffvr6ivonu">
          <span
            style={{
              fontSize: "12.0pt",
              lineHeight: "115%",
              fontFamily: "Roboto",
              color: "#1155CC",
              background: "white"
            }}
          >
            here
          </span>
        </a>
      </span>
    </p>
    <h2>
      <a name="_okjhvnsdmjhv" />
      <span lang="EN-GB">
        If you have enough balance in your Infiniteway CPG
      </span>
    </h2>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B",
          background: "white"
        }}
      >
        You will need to confirm your payment.
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B"
        }}
      >
        <img
          border={0}
          width={398}
          height={273}
          id="image3.png"
          src="images/img-faquser-2.png"
        />
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h2>
      <a name="_1h6yk1btjuua" />
      <span lang="EN-GB">
        If you do not have enough balance in your Infiniteway CPG account
      </span>
    </h2>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B",
          background: "white"
        }}
      >
        You will have two options to choose from: buy token and deposit.{" "}
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h3
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_mcoiv9vqtb9x" />
      <span lang="EN-GB">How to buy INW token with credit/debit card?</span>
    </h3>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", marginTop: "12.0pt", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          Select [buy token] on the top of your screen, or visit{" "}
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          {" "}
        </span>
        <span lang="EN-GB" style={{ color: "windowtext" }}>
          <a href="https://www.indcoinx.com/buy">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "152%",
                color: "#1155CC",
                background: "white"
              }}
            >
              https://www.indcoinx.com/buy
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          {" "}
          . (NOTE : You will be directed to this page when you do not have
          enough balance for your payment.)
          <br />
          <br />
        </span>
        <span lang="EN-GB" style={{ fontSize: "12.0pt", lineHeight: "152%" }}>
          <img
            border={0}
            width={602}
            height={268}
            id="image5.png"
            src="images/img-withdraw-3.png"
          />
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Select your currency. If you can t find your currency, visit{" "}
        </span>
        <span lang="EN-GB" style={{ color: "windowtext" }}>
          <a href="#_lfi8penayc16">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "152%",
                color: "#1155CC",
                background: "white"
              }}
            >
              this page
            </span>
          </a>
        </span>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          .
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Enter the amount for buying tokens.If you enter the fiat amount, the
          received token will be automatically calculated. Or you may enter the
          amount of receiving the tokens, the amount of fiat you ll have to pay
          will be automatically calculated.
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Click [Buy Now].{" "}
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Select [Credit/Debit Card] and click [Pay Now].
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Enter your credit/debit card details and click [Pay Now].
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          After the payment is done, you ll receive your INW tokens in your CPG
          balance.
        </span>
      </li>
      <li className="MsoNormal" style={{ color: "#11193B" }}>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          You ll be able to make your payment with the amount received in your
          Infiniteway CPG balance.
        </span>
      </li>
    </ol>
    <h3>
      <a name="_8gm4scvv875q" />
      <span lang="EN-GB">
        If you have INW token in your wallet, pay with the tokens in your wallet
      </span>
    </h3>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li className="MsoNormal" style={{ color: "#11193B" }}>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          Select [deposit] on the top of your screen, or visit{" "}
        </span>
        <span lang="EN-GB" style={{ color: "windowtext" }}>
          <a href="https://www.indcoinx.com/deposit">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "115%",
                fontFamily: "Roboto",
                color: "#1155CC",
                background: "white"
              }}
            >
              https://www.indcoinx.com/deposit
            </span>
          </a>
        </span>
      </li>
      <li className="MsoNormal" style={{ color: "#11193B" }}>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          Connect your wallet. It will show your INW balance on the page.
        </span>
      </li>
      <li className="MsoNormal" style={{ color: "#11193B" }}>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          Enter the amount to deposit and click [Deposit]
        </span>
      </li>
    </ol>
    <p className="MsoNormal" style={{ marginLeft: "36.0pt" }}>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B",
          background: "white"
        }}
      >
        <br />
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: "Roboto",
          color: "#11193B"
        }}
      >
        <img
          border={0}
          width={442}
          height={336}
          id="image1.png"
          src="images/img-withdraw-4.png"
        />
      </span>
    </p>
    <ol style={{ marginTop: "0cm" }} start={4} type={1}>
      <li className="MsoNormal" style={{ color: "#11193B" }}>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          You ll have to approve the transaction in your wallet.
        </span>
      </li>
      <li className="MsoNormal" style={{ color: "#11193B" }}>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          After the transaction is done, you ll receive the amount in your
          Infiniteway CPG account balance.{" "}
        </span>
      </li>
      <li className="MsoNormal" style={{ color: "#11193B" }}>
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: "Roboto",
            background: "white"
          }}
        >
          You ll be able to make your payment with the amount received in your
          Infiniteway CPG balance.
          <br />
          (NOTE: If you are not directed to the payment page, you will need to
          go back to your ecommerce website and make a new payment in order to
          complete the payment.)
        </span>
      </li>
    </ol>
    <p className="MsoNormal">
      <span lang="EN-GB">&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_orjrkxynl4ho" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How to withdraw your INW token?
      </span>
    </h1>
    <ol style={{ marginTop: "0cm" }} start={1} type={1}>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", marginTop: "12.0pt", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Visit the page{" "}
        </span>
        <span lang="EN-GB" style={{ color: "windowtext" }}>
          <a href="https://www.indcoinx.com/withdraw">
            <span
              style={{
                fontSize: "12.0pt",
                lineHeight: "152%",
                color: "#1155CC",
                background: "white"
              }}
            >
              https://www.indcoinx.com/withdraw
            </span>
          </a>
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Connect your wallet
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Enter your email for verification.It will show your balance of your
          Infiniteway CPG account.
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Enter the amount for withdraw and the OTP that is received in your
          email.
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          Click [WithdrawToken].
        </span>
      </li>
      <li
        className="MsoNormal"
        style={{ color: "#11193B", marginBottom: "12.0pt", lineHeight: "152%" }}
      >
        <span
          lang="EN-GB"
          style={{
            fontSize: "12.0pt",
            lineHeight: "152%",
            background: "white"
          }}
        >
          You'll have to wait for up to 48 hours for the admin to verify your
          withdrawal.
        </span>
      </li>
    </ol>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "0cm",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{ fontSize: "12.0pt", lineHeight: "152%", color: "#11193B" }}
      >
        <img
          border={0}
          width={463}
          height={403}
          id="image2.png"
          src="images/img-withdraw-5.png"
        />
      </span>
    </p>
    <p className="MsoNormal">
      <span
        lang="EN-GB"
        style={{
          fontSize: "11.5pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        &nbsp;
      </span>
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "16.0pt",
        marginLeft: "0cm",
        pageBreakAfter: "auto",
        background: "white"
      }}
    >
      <a name="_us1j9qm22z3l" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "115%",
          color: "#11193B",
          background: "white"
        }}
      >
        How can I check my transaction history?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "0cm",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Select [
      </span>
      <span lang="EN-GB">
        <a href="https://www.indcoinx.com/reports">
          <span
            style={{
              fontSize: "12.0pt",
              lineHeight: "152%",
              color: "#1155CC",
              background: "white"
            }}
          >
            Reports
          </span>
        </a>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        ] on top of the page. You will be able to see your :{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Payment Report : The report of all your payments to ecommerce websites.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "36.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Buy Tokens Report : The history of buying tokens on CPG website.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginLeft: "36.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Deposit Report : The history of depositing on CPG website.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Withdraw Report : The history of withdrawal of CPG website.
      </span>
    </p>
    <h1
      style={{
        marginTop: "12.0pt",
        marginRight: "0cm",
        marginBottom: "12.0pt",
        marginLeft: "0cm",
        lineHeight: "152%"
      }}
    >
      <a name="_lfi8penayc16" />
      <span
        lang="EN-GB"
        style={{
          fontSize: "24.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Why isn't my currency available?
      </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "9.0pt",
        marginRight: "0cm",
        marginBottom: "9.0pt",
        marginLeft: "0cm",
        lineHeight: "150%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "150%",
          color: "#11193B",
          background: "white"
        }}
      >
        First, double check the drop down list. If your currency is still not
        available, it could be missing from the list because:
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "11.0pt",
        marginRight: "0cm",
        marginBottom: ".0001pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        Stripe hasn t integrated with your curency, OR
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "11.0pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt",
        lineHeight: "152%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        ●
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "152%",
          color: "#11193B",
          background: "white"
        }}
      >
        INW tokens has no conversion to your currency{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "9.0pt",
        marginRight: "0cm",
        marginBottom: "9.0pt",
        marginLeft: "0cm",
        lineHeight: "150%"
      }}
    >
      <span
        lang="EN-GB"
        style={{
          fontSize: "12.0pt",
          lineHeight: "150%",
          color: "#11193B",
          background: "white"
        }}
      >
        If you would like to request that we add your currency, please contact
        support.
      </span>
    </p>
  </div>
</>

  
     </div>
      <FooterCustom/>
    </div>
  );
}
